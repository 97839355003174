import { STORAGE_KEYS, getFromStorage, showError, showToast } from "../constants";
// import useTranslation  from "../hooks/Translation";
import { generateEncryptedKeyBody } from "./crypto";

const language= getFromStorage(STORAGE_KEYS.language)

export const handleDelete = async (
  deleteById: any,
  selectedId: string,
  getAllData: () => void
) => {
  console.log(selectedId, "selectedId");
 

  if (selectedId) {
    try {
      console.log(selectedId, "id");
      const response = await deleteById({ id: selectedId }).unwrap();
      if (response?.statusCode === 200) {
        showToast(language==="en"?`Deleted Successfully`:"حذف بنجاح");
        if (getAllData) {
          getAllData(); // Assuming getAllData is a function to fetch updated data
        }
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  }
};
export const handleDeleteStories = async (
  deleteById: any,
  selectedId: string,
  Id: string | undefined,
  getAllData: () => void
) => {
  console.log(selectedId, "selectedId");

  if (selectedId) {
    try {
      const response = await deleteById({ id: selectedId }).unwrap();
      if (response?.statusCode === 200) {
        showToast(language==="en"?`Deleted Successfully`:"حذف بنجاح");
        if (getAllData) {
          getAllData(); // Assuming getAllData is a function to fetch updated data
        }
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  }
};

export const updateStatus = async (
  id: string,
  status: boolean,
  updateStatusMutation: any,
  setData: any,
  data: any
) => {
  // const translations=useTranslation()as any
  console.log(id, "ididid");
  console.log(status, "sss");
  const response = await updateStatusMutation({
    id,
    // body: encryptedBody,
  }).unwrap();
  console.log(response);
  if (response?.statusCode === 200) {
    const updatedData = data.map((x: any) => {
      if (x._id === id) {
        return {
          ...x,
          isBlocked: status,
        };
      }
      return x;
    });
    console.log(updatedData, "dad");
    setData(updatedData);
    // showToast("Status updated successfully");
    if (!status) {
      console.log("status: ", status);
      showToast(language==="en"?"Enabled successfully":"تم التمكين بنجاح");
    } else {
      showToast(language==="en"?"Disabled successfully":"تم التعطيل بنجاح");
    }
  } else {
    showError("Please try again");
  }
};
export const updateStatusNew = async (
  id: string,
  parent:string,
  status: boolean,
  updateStatusMutation: any,
  setData: any,
  data: any
) => {
  // const translations=useTranslation()as any
  console.log(id, "ididid");
  console.log(status, "sss");
  const response = await updateStatusMutation({
    id,
    // body: encryptedBody,
  }).unwrap();
  console.log(response);
  if (response?.statusCode === 200) {
    const updatedData = data.map((x: any) => {
      if (x._id === parent) {
        return {
          ...x,
          isBlocked: status,
        };
      }
      return x;
    });
    console.log(updatedData, "dad");
    setData(updatedData);
    // showToast("Status updated successfully");
    if (!status) {
      console.log("status: ", status);
      showToast(language==="en"?"Enabled successfully":"تم التمكين بنجاح");
    } else {
      showToast(language==="en"?"Disabled successfully":"تم التعطيل بنجاح");
    }
  } else {
    showError("Please try again");
  }
};
