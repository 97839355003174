import {
  Box,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { Dispatch, useState, SetStateAction } from "react";
import SearchBar from "../../components/SearchBar";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";
import { AdminPanel } from "../../types/General";
import { isValidInput } from "../../utils/validations";
import { Pagination } from "../../components";
import {
  useAdvertisementRequestMutation,
  useLazyDeleteAdvertisementQuery,
  usePostAcceptRejectAdvertiserMutation,
} from "../../services/advertiser";
import WarnModal from "../../components/WarnModal";
import { handleDelete } from "../../utils/commonFunctions";
import { Loader, showError, showToast } from "../../constants";
import useTranslation from "../../hooks/Translation";

type props = {
  hidePermission: Permissions | null | undefined;
  advertisement: AdminPanel[];
  setAdvertisement: Dispatch<SetStateAction<AdminPanel[]>>;
  setDebouncedSearchTerm: Dispatch<SetStateAction<string>>;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  page: number;
  totalPages: number;
  setValue: Dispatch<SetStateAction<number>>;

  onPageChange: (newPage: number) => void;
  getAdvertisersList: () => void;
};
interface AdvertiserStatusMap {
  [key: string]: string;
}
const Pending = ({
  hidePermission,
  advertisement,
  setAdvertisement,
  setDebouncedSearchTerm,
  searchTerm,
  setSearchTerm,
  page,
  totalPages,
  onPageChange,
  getAdvertisersList,
  setValue,
}: props) => {
  const userData = useAuth();
  const navigate = useNavigate();
  const [advertisementStatus, setAdvertisementStatus] =
    useState<AdvertiserStatusMap>({});
  const [open, setOpen] = useState<boolean>(false);
  const [selectId, setSelectedId] = useState<string>("");
  // const [searchTerm, setSearchTerm] = useState<string>("");
  // const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [deleteById] = useLazyDeleteAdvertisementQuery();
  const [acceptRejectAdvertiser, GetApprovalData] =
    useAdvertisementRequestMutation();

  const handleAdvertiserApproval = async (
    id: string,
    event: SelectChangeEvent
  ) => {
    const status = event.target.value as string;
    let body = {
      advertisementId: id,
      status: status,
      appKey: new Date().toISOString(),
    };
    console.log(body);
    try {
      const response = await acceptRejectAdvertiser({
        body: body,
      }).unwrap();
      console.log(response, "fff");
      if (response?.statusCode === 200) {
        showToast(response?.message || "");
        if (response?.data?.status === "Accepted") {
          setValue(0);
        } else {
          setValue(2);
        }
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };
  function createData(
    name: string,
    userName: string,
    phone: string,
    email: string,
    planName: string,
    planPrice: string,
    postedAds: string,
    earnings: string
  ) {
    return {
      name,
      userName,
      phone,
      email,
      planName,
      planPrice,
      postedAds,
      earnings,
    };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData(
      "Abdul Hussian",
      "Abdul67",
      "+971056734321",
      "abdulhussian67@gmail.com",
      "Starter",
      "1 Month / 600 SAR",
      "10",
      "600 SAR"
    ),
  ];

  const translations = useTranslation() as any;

  return (
    <div>
      <Loader isLoad={GetApprovalData?.isLoading} />
      <Box className="cards_header">
        <SearchBar
          searchTerm={searchTerm}
          setDebouncedSearchTerm={setDebouncedSearchTerm}
          value={searchTerm}
          onCross={() => setSearchTerm("")}
          onChange={(val: any) => {
            if (isValidInput(val.target.value)) {
              setSearchTerm(val.target.value);
            }
          }}
        />
        {/* <Box className="cards_header_right">
          <Button className="btn btn_primary">
            <FileDownloadIcon /> Export CSV
          </Button>
          <Button
            className="btn btn_primary"
            onClick={() => navigate("/manage-advertisers/add")}
          >
            Add Advertiser
          </Button>
        </Box> */}
      </Box>
      <TableContainer className="table_container">
        <Box className="heading"></Box>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {translations.table_heads.sno || ""}
              </TableCell>
              <TableCell align="center">
                {translations.table_heads.ad_image || ""}
              </TableCell>
              <TableCell align="center">
                {translations.table_heads.advertiser_details || ""}
              </TableCell>
              {/* <TableCell align="center">Ad Image</TableCell> */}
              <TableCell align="center">
                {translations.table_heads.ad_title || ""}
              </TableCell>
              <TableCell align="center">
                {translations.table_heads.web_link || ""}
              </TableCell>
              <TableCell align="center">
                {translations.table_heads.total_cost || ""}
              </TableCell>
              <TableCell align="center">
                {" "}
                {translations.table_heads.status || ""}
              </TableCell>
              <TableCell align="center">
                {translations.table_heads.actions || ""}
              </TableCell>
            </TableRow>
          </TableHead>

          {advertisement.length ? (
            advertisement?.map((item, index) => (
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    {(page - 1) * 10 + index + 1}
                  </TableCell>
                  <TableCell>
                    {item?.uploads[0]?.type === "IMAGE" ? (
                      <figure className="bnr_img">
                        <img
                          src={
                            item?.uploads[0]?.link || "/static/images/post.png"
                          }
                          alt=""
                        />
                      </figure>
                    ) : (
                      <figure className="bnr_img">
                        <img
                          src={
                            item?.uploads[0]?.thumbnail ||
                            "/static/images/post.png"
                          }
                          alt=""
                        />
                      </figure>
                    )}
                  </TableCell>
                  <TableCell>
                    <p>{item?.fullName}</p>
                    <p>{item?.email}</p>
                  </TableCell>
                  <TableCell>{item?.title}</TableCell>
                  <TableCell>
                    <a href={item?.webLink}>{item?.webLink}</a>
                  </TableCell>
                  <TableCell>AED {item?.totalCost}</TableCell>

                  <TableCell align="center">
                    <Select
                      className="select_div "
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={advertisementStatus[item?._id] || ""}
                      onChange={(e) => {
                        const status = e.target.value as string;
                        console.log("Status", status);
                        setAdvertisementStatus((prev) => ({
                          ...prev,
                          [item?._id]: status,
                        }));
                        console.log(advertisementStatus[item?._id], "ss");
                        handleAdvertiserApproval(item?._id, e);
                      }}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        {translations.manage_advertisements.select || ""}
                      </MenuItem>
                      <MenuItem value="Accepted">
                        {translations.manage_advertisements.approve || ""}
                      </MenuItem>
                      <MenuItem value="Rejected">
                        {translations.manage_advertisements.reject || ""}
                      </MenuItem>
                    </Select>
                  </TableCell>

                  <TableCell>
                    <Box className="table_actions">
                      <IconButton
                        onClick={() =>
                          navigate(
                            "/manage-advertisements/details/" + item?._id,
                            {
                              state: { userStatus: "Pending" },
                            }
                          )
                        }
                      >
                        <VisibilityIcon />
                      </IconButton>
                      {/* <IconButton
                    onClick={() => navigate("/manage-advertisements/edit")}
                  >
                    <ModeEditIcon />
                  </IconButton> */}
                      {hidePermission?.isDelete ||
                      userData?.role === "ADMIN" ? (
                        <IconButton
                          onClick={() => {
                            setOpen(true);
                            setSelectedId(item?._id);
                          }}
                          // onClick={() => {
                          //   setOpen(true);
                          //   setSelectedId(item?._id);
                          // }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      ) : null}
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            ))
          ) : (
            <TableBody>
              <TableCell align="center" colSpan={10} sx={{ color: "#051140" }}>
                {translations.manage_advertisements.no_found || ""}
              </TableCell>
            </TableBody>
          )}

          {/* <TableBody>
            {/* {rows.map((row, i) => ( */}
          {/* <TableRow>
              <TableCell align="center">{1}</TableCell>
              <TableCell>12/12/2023</TableCell>
              <TableCell>
                <p>Abdul Hussain</p>
                <p>abdul@yopmail.com</p>
              </TableCell>
              <TableCell>See the Northern Lights</TableCell>
              <TableCell>www.northernlights.com</TableCell>
              <TableCell>$10</TableCell>

              <TableCell>
                <Switch {...label} defaultChecked size="small" />
              </TableCell>
              <TableCell>
                <Box className="table_actions">
                  <IconButton
                    onClick={() =>
                      navigate("/manage-advertisements/details", {
                        state: {
                          userStatus: "PENDING",
                        },
                      })
                    }
                  >
                    <VisibilityIcon />
                  </IconButton>
                  {/* <IconButton
                    onClick={() => navigate("/manage-advertisements/edit")}
                  >
                    <ModeEditIcon />
                  </IconButton> */}
          {/* {hidePermission?.isDelete || userData?.role === "ADMIN" ? (
                    <IconButton>
                      <DeleteIcon />
                    </IconButton>
                  ) : null}
                </Box>
              </TableCell>
            </TableRow> */}
          {/* ))} */}
          {/* </TableBody> */}
        </Table>
      </TableContainer>
      <Pagination
        module={advertisement}
        page={page}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
      <WarnModal
        setOpen={setOpen}
        open={open}
        name={translations.manage_advertisements.advertisement || ""}
        handleDelete={() => {
          handleDelete(deleteById, selectId, getAdvertisersList);
        }}
      />
    </div>
  );
};

export default Pending;
