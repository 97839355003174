import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { STORAGE_KEYS, getFromStorage } from "../constants";
import useTranslation from "../hooks/Translation";


type props = {
  module: any;
  page: number;
  onPageChange: (newPage: number) => void;
  totalPages: number;
};

const Pagination = ({ module, page, onPageChange, totalPages }: props) => {
  const isArb=getFromStorage(STORAGE_KEYS.language)
  const translation=useTranslation() as any
  
  return (
    <div className="pagination" style={{ marginTop: "20px" }}>
      <p>
        <span>{translation.Globals.showing} {module?.length ? `${module?.length}` : 0} {translation.Globals.items} </span>
      </p>
      <div className="pages">
        {isArb==="ar"?
        (
          <button
          className="prevbtn"
          onClick={() => onPageChange(page - 1)}
          disabled={page === 1}
        >
          <ArrowForwardIosIcon />
          
        </button>
        ):(
          <button
          className="prevbtn"
          onClick={() => onPageChange(page - 1)}
          disabled={page === 1}
        >
          
          <ArrowBackIosIcon />
        </button>
        )}
       
        <div className="count">
          {Array.from({ length: totalPages }).map((_, index) => (
            <button
              className={page === index + 1 ? "actv" : "inActv"}
              key={index}
              onClick={() => onPageChange(index + 1)}
              disabled={page === index + 1}
            >
              {index + 1}
            </button>
          ))}
        </div>
        {isArb==="ar"?(
          <button
          className="prevbtn"
          onClick={() => onPageChange(page + 1)}
          disabled={page === totalPages}
        >
          <ArrowBackIosIcon />
          
        </button>
        ):(
          <button
          className="prevbtn"
          onClick={() => onPageChange(page + 1)}
          disabled={page === totalPages}
        >
          <ArrowForwardIosIcon />
        </button>
        )}
      
      </div>
    </div>
  );
};

export default Pagination;

// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import { FormControl, MenuItem, Select } from "@mui/material";
// import { Dispatch, SetStateAction } from "react";
// import { STORAGE_KEYS, getFromStorage } from "../constants";
// import useTranslation from "../hooks/Translation";

// type props = {
//   module: any;
//   page: number;
//   onPageChange: (newPage: number) => void;
//   totalPages: number;
//   // setPage: Dispatch<SetStateAction<number>>;
// };

// const Pagination = ({
//   module,
//   page,
//   onPageChange,
//   totalPages,
  
// }: props) => {
//   const translation = useTranslation() as any;
//   const isArb=getFromStorage(STORAGE_KEYS.language)
//   return (
//     <div className="pagination" style={{ marginTop: "20px" }}>
//       <p>
//         <span>Showing {module?.length ? `${module?.length}` : 0} items </span>
//       </p>
//       <div className="pages">
//         {totalPages > 5 ? (
//           <FormControl fullWidth>
//             <Select
//               className="select_pgntn"
//               style={{ width: 60 }}
//               labelId="demo-simple-select-label"
//               id="demo-simple-select"
//               inputProps={{
//                 "aria-label": "Without label",
//               }}
//               // IconComponent={
//               //   <svg className="SVGI">

//               //   </svg>
//               // }
//               displayEmpty
//               value={page}
//               onChange={(pages: any) => onPageChange(pages.target.value)}
//             >
//               <MenuItem value="" disabled>
//                 {translation.Globals.select}
//               </MenuItem>
//               {Array.from({ length: totalPages }).map((_, index) => (
//                 <MenuItem key={index + 1} value={index + 1}>
//                   {index + 1}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//         ) : null}
// {/* 
//         <button
//           className="prevbtn"
//           onClick={() => onPageChange(page - 1)}
//           disabled={page === 1}
//         >
//           <ArrowBackIosIcon />
//         </button> */}
//         {isArb==="ar"?
//         (
//           <button
//           className="prevbtn"
//           onClick={() => onPageChange(page - 1)}
//           disabled={page === 1}
//         >
//           <ArrowForwardIosIcon />
          
//         </button>
//         ):(
//           <button
//           className="prevbtn"
//           onClick={() => onPageChange(page - 1)}
//           disabled={page === 1}
//         >
          
//           <ArrowBackIosIcon />
//         </button>
//         )}
//         <div className="count">
//           {totalPages > 6
//             ? Array.from({ length: totalPages })
//                 .slice(0, 5)
//                 .map((_, index) => (
//                   <button
//                     className={page === index + 1 ? "actv" : "inActv"}
//                     key={index}
//                     onClick={() => onPageChange(index + 1)}
//                     disabled={page === index + 1}
//                     type="button"
//                   >
//                     {index + 1}
//                   </button>
//                 ))
//                 .concat(
//                   <span
//                     key="ellipsis"
//                     className="ellipsis"
//                     style={{ color: "black" }}
//                   >
//                     ...
//                   </span>,
//                   <button
//                     className="actv"
//                     type="button"
//                     style={{
//                       display:
//                         page < 6 || page === totalPages ? "none" : undefined,
//                     }}
//                     key={totalPages}
//                   >
//                     {page}
//                   </button>,
//                   <button
//                     className={page === totalPages ? "actv" : "inActv"}
//                     key={totalPages}
//                     type="button"
//                     onClick={() => onPageChange(totalPages)}
//                     disabled={page === totalPages}
//                   >
//                     {totalPages}
//                   </button>
//                 )
//             : Array.from({ length: totalPages }).map((_, index) => (
//                 <button
//                   className={page === index + 1 ? "actv" : "inActv"}
//                   type="button"
//                   key={index}
//                   onClick={() => onPageChange(index + 1)}
//                   disabled={page === index + 1}
//                 >
//                   {index + 1}
//                 </button>
//               ))}
//         </div>
//         {/* <button
//           className="prevbtn"
//           onClick={() => onPageChange(page + 1)}
//           disabled={page === totalPages}
//         >
//           <ArrowForwardIosIcon />
//         </button> */}
//           {isArb==="ar"?(
//           <button
//           className="prevbtn"
//           onClick={() => onPageChange(page + 1)}
//           disabled={page === totalPages}
//         >
//           <ArrowBackIosIcon />
          
//         </button>
//         ):(
//           <button
//           className="prevbtn"
//           onClick={() => onPageChange(page + 1)}
//           disabled={page === totalPages}
//         >
//           <ArrowForwardIosIcon />
//         </button>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Pagination;
