import { useLocation, useNavigate, useParams } from "react-router-dom";
import MainContainer from "../../layout/MainContainer"
import { 
    Box,
     Button,
     Typography,
     Grid,
     Card,
     CardContent
 } from "@mui/material";
import { useLazyGetComplaintsByIdQuery, useLazyGetQueriesByIdQuery } from "../../services/advertiser";
import { showError } from "../../constants";
import { useEffect, useState } from "react";
import moment from "moment";
import useTranslation from "../../hooks/Translation";




const QueriesDetails=()=>{

const [complaintDetails,setComplaintsDetails]=useState<any>([])
const navigate=useNavigate()

const {id}=useParams()
const {state}=useLocation()


const [getComplainDetails,{isLoading}]=useLazyGetComplaintsByIdQuery()
const [getQuery]=useLazyGetQueriesByIdQuery()

const getDetails=async(id:string)=>{
    try{
      if(state.value==1){
        const response:any=await getComplainDetails({
            id:id
        })

        if(response?.data?.statusCode===200){
          
            setComplaintsDetails(response?.data?.data?.queries || [])
        }else{
            setComplaintsDetails([])
        }
      }else{
        const response:any=await getQuery({
          id:id
      })
      
      if(response?.data?.statusCode===200){
        
          setComplaintsDetails(response?.data?.data?.queries || [])
      }else{
          setComplaintsDetails([])
      }
      }
    }catch(error:any){
        showError(error?.data?.message || "")
    }
}
useEffect(()=>{
    if(id){
      

        getDetails(id)
      
    }
},[])
console.log(complaintDetails,"ddd")

const translations=useTranslation()as any
    return(
        <div>
        <MainContainer>
            <div className="main_loyout">
            <div className="dashboard">
          <h1 className="mn_hdng">{translations.customer_support.view_details||""}</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/customer-support");
            }}
          >
            {translations.Globals.back||""}
          </Button>
        </div>

        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography
                  className="detail_title mn_hdng"
                  component="h2"
                  mb={3}
                >
                  {translations.customer_support.user_details||""}
                </Typography>
              </Grid>
              {/* <Grid item lg={2} md={2} sm={6} xs={12}>
                <figure className="profile_img">
                  <img src="/static/images/user_placeholder.png" alt="" />
                </figure>
              </Grid> */}
              <Grid item xs={12}  className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">{translations.customer_support.fname||""}</Typography>
                      <Typography component="p">
                        {complaintDetails[0]?.userName}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">{translations.customer_support.phone||""}</Typography>
                      <Typography component="p">
                      {complaintDetails[0]?.userName}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">{translations.customer_support.email||""}</Typography>
                      <Typography component="p">
                      {complaintDetails[0]?.email}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item mt={3} xs={12}  className="view_box_list">
                    <Typography
                      className="detail_title mn_hdng"
                      component="h2"
                      mb={3}
                      
                    >
                      {translations.customer_support.query_details||""}
                    </Typography>
                    {/* <Grid container spacing={3}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box>
                          <Typography component="h5">Uploads</Typography>
                          <Box className="post_div">
                            {postDetail?.uploads?.length
                              ? postDetail?.uploads?.map((item) => {
                                  return (
                                    <>
                                      {item?.type === "IMAGE" ? (
                                        <figure
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            handlePopup(item?.link, item?.type)
                                          }
                                        >
                                          <img src={item?.link || ""} alt="" />
                                        </figure>
                                      ) : (
                                        <video
                                          style={{ cursor: "pointer" }}
                                          autoPlay
                                          muted
                                          loop
                                          playsInline
                                          className="hm_vd"
                                          preload="yes"
                                          onClick={() =>
                                            handlePopup(item?.link, item?.type)
                                          }
                                        >
                                          <source
                                            src={item?.link || ""}
                                            type="video/mp4"
                                          />
                                        </video>
                                      )}
                                    </>
                                  );
                                })
                              : null}
                          </Box>
                        </Box>
                      </Grid> */}

                      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ marginBottom: 3 }}>
                        <Box>
                          <Typography component="h5">{translations.customer_support.desc||""}</Typography>
                          <Typography component="p">
                          {complaintDetails[0]?.description}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid container spacing={3}>
                     

                   
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">{translations.customer_support.date||""}</Typography>
                          <Typography component="p">
                          
                          {moment(complaintDetails[0]?.createdAt).format(
                              "DD/MM/YYYY"
                            )}
                        
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">{translations.customer_support.status||""}</Typography>
                          <Typography component="p">
                          {complaintDetails[0]?.status==0?"Pending":"Resolved"}
                          </Typography>
                        </Box>
                      </Grid>
                     
                   
                    
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12} sx={{marginTop:3}}>
                        <Box>
                          <Typography component="h5">{translations.customer_support.reply||""}</Typography>
                          <Typography component="p">
                        {complaintDetails[0]?.reply==""?"---":complaintDetails[0]?.reply}
                          </Typography>
                        </Box>
                      </Grid>
                    
                      {/* <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Upload Time</Typography>
                          <Typography component="p">
                            {moment(postDetail?.createdAt).format("h:mm A")}
                          </Typography>
                        </Box>
                      </Grid> */}
                      {/* <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Total Comments</Typography>
                          <Typography component="p">
                            {postDetail?.commentCount}
                          </Typography>
                        </Box>
                      </Grid> */}
                      {/* <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Total Likes</Typography>
                          <Typography component="p">
                            {postDetail?.likesCount}
                          </Typography>
                        </Box>
                      </Grid> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            
          </CardContent>
        </Card>


            </div>
        </MainContainer>
        </div>
    )
                    

}

export default QueriesDetails
