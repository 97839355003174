import React, { PropsWithoutRef, useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Typography,
  Button,
  Modal,
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";

import LockResetIcon from "@mui/icons-material/LockReset";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import {
  Loader,
  SIDEBAR_WIDTH,
  STORAGE_KEYS,
  getFromStorage,
  removeFromStorage,
  setToStorage,
  showError,
  showToast,
} from "../constants";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import "./Layout.scss";
import useAuth from "../hooks/useAuth";
import { useAppDispatch } from "../hooks/store";
import { generateResponsiveStyle } from "../utils/ModalStyle";
import { useLazyGetUserQuery, useLazyLogoutQuery } from "../services/auth";
import { resetAuth, setCredentials } from "../reducers/authSlice";
import { useLanguageContext } from "../context/LanguageContext";
import useTranslation from "../hooks/Translation";

interface Props {
  handleSidebarToggle: () => void;
}

function Topbar({ handleSidebarToggle }: PropsWithoutRef<Props>) {
  const getLang = getFromStorage(STORAGE_KEYS.language);
  const user = useAuth();
  const style = generateResponsiveStyle();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { language, changeLanguage } = useLanguageContext();

  const [anchorElLang, setAnchorElLang] = React.useState<null | HTMLElement>(
    null
  );
  const openLang = Boolean(anchorElLang);

  const [openModal, setOpenModal] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);

  const [getUser] = useLazyGetUserQuery();
  const [getLogout, { isLoading }] = useLazyLogoutQuery();

  const open = Boolean(anchorEl);
  const open1 = Boolean(anchorEl1);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickLang = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElLang(event.currentTarget);
  };

  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleLangChange = (val: string) => {
    setToStorage(STORAGE_KEYS.language, val);
    changeLanguage(val);
    setAnchorElLang(null);
    window.location.reload();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const getUserDetails = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    try {
      const result = await getUser({}).unwrap();
      if (result?.statusCode === 200 && token) {
        dispatch(
          setCredentials({
            user: result?.data,
            token: token,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    if (token) {
      dispatch(
        setCredentials({
          user: null,
          token: token,
        })
      );
    }
  }, []);

  console.log(user,"user")

  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    const userData = getFromStorage(STORAGE_KEYS.credentials);
    if (token) {
      dispatch(
        setCredentials({
          token: `${token}`,
          user: JSON.parse(`${userData}`),
        })
      );
    }
    getUserDetails();
  }, []);

  useEffect(() => {
    if (window) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);

  const handleLogout = async () => {
    try {
      const result = await getLogout({}).unwrap();
      if (result?.statusCode === 200) {
        removeFromStorage(STORAGE_KEYS.token);
        dispatch(resetAuth());
        navigate("/", { replace: true });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const change = () => {
    const body = document.body; // Use document.body directly to get the body element
    if (body) {
      const language = getFromStorage(STORAGE_KEYS.language);
      const isArabic = language === "ar";
      body.classList.remove(isArabic ? "english_lang" : "arabic_lang");
      body.classList.add(isArabic ? "arabic_lang" : "english_lang");
    }
  };
  useEffect(() => {
    change();
  }, [getLang]);

  useEffect(() => {
    if (!language) {
      setToStorage(STORAGE_KEYS.language, "en");
    }
  }, []);

  useEffect(()=>{
      if(user?.isBlocked){
        removeFromStorage(STORAGE_KEYS.token);
        dispatch(resetAuth());
        navigate("/", { replace: true });
        showError("Your account is blocked")
      }
  },[user])

const translation=useTranslation() as any

  return (
    <Box className="container top_bar">
      <Loader isLoad={isLoading} />
      <AppBar
        position="fixed"
        className="topbar"
        sx={{
          width: { lg: `calc(100% - ${SIDEBAR_WIDTH}px)` },
          ml: { lg: `${SIDEBAR_WIDTH}px` },
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            color="inherit"
            aria-label="open sidebar"
            edge="start"
            onClick={handleSidebarToggle}
            style={{ backgroundColor: "#72cedd" }}
            sx={{
              display: { xs: "flex", lg: "none" },
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              flexGrow: 1,
              justifyContent: "flex-end",
            }}
          >
            <Box
              sx={{
                mr: 2,
                // ml: getLang === "ar" ? 2 : 0,
              }}
            >
              <IconButton
                className="notification_btn"
                aria-controls={openLang ? "basic-menu1" : undefined}
                aria-haspopup="true"
                aria-expanded={openLang ? "true" : undefined}
                onClick={handleClickLang}
              >
                <LanguageIcon />
              </IconButton>
              <Menu
                id="basic-menu1"
                anchorEl={anchorElLang}
                open={openLang}
                onClose={() => setAnchorElLang(null)}
                MenuListProps={{
                  "aria-labelledby": "basic-button1",
                }}
              >
                <MenuItem value="en" onClick={() => handleLangChange("en")}>
                  English
                </MenuItem>
                <MenuItem value="ar" onClick={() => handleLangChange("ar")}>
                  Arabic
                </MenuItem>
              </Menu>
            </Box>
            <Box sx={{ mr: 2, ml:getLang==="ar"?2:0}}>
              <IconButton
                className="notification_btn"
                aria-controls={open1 ? "basic-menu1" : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? "true" : undefined}
                onClick={()=>{
                  navigate("/recieved-notifications")
                }}
              >
                <NotificationsActiveIcon />
              </IconButton>
              <Menu
                className="notiDropdown_menu"
                id="basic-menu1"
                anchorEl={anchorEl1}
                open={open1}
                onClose={handleClose1}
                MenuListProps={{
                  "aria-labelledby": "basic-button1",
                }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 20,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <Box className="noti_head">
                  <Typography component="h2">Notifications</Typography>
                  <Button
                    className="btn btn_primary sm"
                    onClick={() => navigate("/recieved-notifications")}
                  >
                    See All
                  </Button>
                </Box>
                <ul className="noti_list">
                  <li>
                    <figure>
                      <img src="/static/images/user_placeholder.png" alt="" />
                    </figure>
                    <div className="noti_info">
                      <Typography component="h3">
                        JohnDoe
                        <Typography component="span">1 Day ago</Typography>
                      </Typography>
                      <Typography component="p">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit
                        dolor sit amet consectetur.
                      </Typography>
                    </div>
                  </li>
                  <li>
                    <figure>
                      <img src="/static/images/user_placeholder.png" alt="" />
                    </figure>
                    <div className="noti_info">
                      <Typography component="h3">
                        JohnDoe
                        <Typography component="span">1 Day ago</Typography>
                      </Typography>
                      <Typography component="p">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit
                        dolor sit amet consectetur.
                      </Typography>
                    </div>
                  </li>
                  <li>
                    <figure>
                      <img src="/static/images/user_placeholder.png" alt="" />
                    </figure>
                    <div className="noti_info">
                      <Typography component="h3">
                        JohnDoe
                        <Typography component="span">1 Day ago</Typography>
                      </Typography>
                      <Typography component="p">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit
                        dolor sit amet consectetur.
                      </Typography>
                    </div>
                  </li>
                </ul>
              </Menu>
            </Box>

            <Box>
              <IconButton
                className="topbar_user_btn"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <img
                  src={
                    user?.image
                      ? user?.image
                      : "/static/images/user_placeholder.png"
                  }
                  alt=""
                />
                <span>
                  {(user?.firstName || "User") + " " + (user?.lastName || "")}
                </span>
                <KeyboardArrowDownIcon />
              </IconButton>
              <Menu
                className="userDropdown_menu"
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 10,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  className="userDropdown_menu_list"
                  onClick={() => navigate("/profile")}
                >
                  <IconButton className="drpdwn_icns">
                    <PersonIcon />
                  </IconButton>
                  {translation.Globals.myProfile}
                </MenuItem>
                <MenuItem
                  className="userDropdown_menu_list"
                  onClick={() => navigate("/changepassword")}
                >
                  <IconButton className="drpdwn_icns">
                    <LockResetIcon />
                  </IconButton>
                  {translation.Globals.change_password}
                </MenuItem>

                <MenuItem
                  className="userDropdown_menu_list"
                  onClick={() => setOpenModal(true)}
                >
                  <IconButton className="drpdwn_icns">
                    <LogoutIcon />
                  </IconButton>
                  {translation.Globals.logout}
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Logout Popup */}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="logout_mdl"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center", fontSize: "20px", color: "#1d2b28" }}
          >
           {translation.Globals.want_to_logout}
          </Typography>
          <div className="flexdiv">
            <Button
              className="yesBtn"
              sx={{ color: "#252525", border: "1px solid #252525" }}
              onClick={() => {
                setOpenModal(false);
                handleLogout();
              }}
            >
           {translation.Globals.yes}
            </Button>

            <Button
              sx={{ color: "#252525", border: "1px solid #252525" }}
              onClick={() => setOpenModal(false)}
            >
              {translation.Globals.no}
            </Button>
          </div>
        </Box>
      </Modal>
    </Box>
  );
}

export default Topbar;
