// eslint-disable-next-line import/no-extraneous-dependencies
import LocalizedStrings from "react-localization";
import localization from "../utils/localization";
import { useLanguageContext } from "../context/LanguageContext";

export default function useTranslation() {
  const { language } = useLanguageContext() as any;
  const translation = new LocalizedStrings(localization);

  translation.setLanguage(language);
  return translation;
}
