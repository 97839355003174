import {
  Drawer,
  Toolbar,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Box,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import SummarizeTwoToneIcon from "@mui/icons-material/SummarizeTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LockClockIcon from "@mui/icons-material/LockClock";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ListAltIcon from "@mui/icons-material/ListAlt";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";

import { SIDEBAR_WIDTH } from "../constants";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import FeaturedVideoIcon from "@mui/icons-material/FeaturedVideo";
import QuizIcon from "@mui/icons-material/Quiz";
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import Person3Icon from "@mui/icons-material/Person3";

import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import PaidIcon from "@mui/icons-material/Paid";
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { PropsWithoutRef, useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import useTranslation from "../hooks/Translation";

interface Props {
  mobileOpen: boolean;
  handleSidebarToggle: () => void;
}

export default function Sidebar({
  mobileOpen,
  handleSidebarToggle,
}: PropsWithoutRef<Props>) {
  const userData = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const translation=useTranslation()as any
  const items = [
    {
      label:translation.Globals.dashboard,
      icon: <DashboardIcon />,
      key: "1",
      selected: "/dashboard",
    },
    {
      label:translation.Globals.manage_user ,
      icon: <PeopleIcon />,
      key: "2",
      selected: "/manageusers",
    },
    {
      label: translation.Globals.manage_Advertiser,
      icon: <Person3Icon/>,
      key: "3",
      selected: "/manage-advertisers",
    },
    {
      label:translation.Globals.manage_advertisement,
      icon: <FeaturedVideoIcon />,
      key: "4",
      selected: "/manage-advertisements",
    },
    {
      label: translation.Globals.manage_content,
      icon: <ContentPasteIcon />,
      key: "5",
      selected: "/manage-content",
    },
    {
      label: translation.Globals.manage_journey
      ,
      icon: <VideoLibraryIcon />,
      key: "6",
      selected: "/manage-journeys",
    },
    {
      label: translation.Globals.manage_challenge,
      icon: <ListAltIcon />,
      key: "7",
      selected: "/manage-categories",
    },

    {
      label:translation.Globals.manage_subs,
      icon: <SubscriptionsIcon />,
      key: "8",
      selected: "/manage-subscription",
    },

    {
      label: translation.Globals.manage_rewards,
      icon: <EmojiEventsIcon />,
      key: "9",
      selected: "/manage-rewards",
    },
    {
      label: translation.Globals.customer,
      icon: <HelpCenterIcon />,
      key: "10",
      selected: "/customer-support",
    },
    {
      label: translation.Globals.manage_challenge_dash,
      icon: <ThumbUpIcon />,
      key: "11",
      selected: "/manage-challenges",
    },
    {
      label: translation.Globals.manage_word,
      icon: <LockClockIcon />,
      key: "12",
      selected: "/manage-words",
    },
    {
      label: translation.Globals.manage_payment,
      icon: <PaidIcon />,
      key: "13",
      selected: "/manage-revenue",
    },
    {
      label: translation.Globals.manage_gift,
      icon: <CardGiftcardIcon />,
      key: "14",
      selected: "/manage-gifts",
    },
    {
      label: translation.Globals.manage_notification,
      icon: <NotificationsActiveIcon />,
      key: "15",
      selected: "/manage-notifications",
    },
    {
      label: translation.Globals.report,
      icon: <BarChartIcon />,
      key: "16",
      selected: "/analytics",
    },
    {
      label: translation.Globals.manage_subadmin,
      icon: <PersonAddIcon />,
      key: "17",
      selected: "/manage-subAdmin",
    },
    {
      label: translation.Globals.cms,
      icon: <SummarizeTwoToneIcon />,
      key: "18",
      selected: "/cms",
    },
    {
      label: translation.Globals.faq,
      icon: <QuizIcon />,
      key: "19",
      selected: "/faq",
    },
    
  ];
  const [list, setList] = useState<any>(
    userData?.role === "ADMIN" ? items : userData?.permission
  );
  useEffect(() => {
    let arr = [];
    if (userData?.role === "ADMIN") {
      setList(items);
    } else if (userData?.permission?.length) {
      const permission = userData?.permission;
      arr = items.map((item) => {
        let idx = -1;
        idx = permission?.findIndex((ele: any) => ele?.label === item?.label);
        if (idx > -1) {
          if (permission[idx].isView) {
            return item;
          }
          return false;
        }
      });
      setList(arr);
    }
  }, [userData]);

  console.log( userData?.permission,"permission")

  console.log(list,"list")

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { lg: 0 } }}
      className="sidebar-main-div"
    >
      <Drawer
        className="sidebar_drawer"
        variant="temporary"
        open={mobileOpen}
        onClose={handleSidebarToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: SIDEBAR_WIDTH,
          },
        }}
      >
        <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
          <figure>
            <img
              src="/static/images/logo.png"
              alt=""
              onClick={() => navigate("/dashboard")}
            />
          </figure>
          <IconButton
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "white",
              display: { lg: "none" },
            }}
            onClick={handleSidebarToggle}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        {list?.length
          ? list
              .filter((ele: any) => ele !== undefined)
              .map((item: any) => {
                const isSelected = location.pathname.includes(item?.selected);
                return (
                  <List
                    key={item?.label}
                    className="sidebr-lst"
                    sx={{ padding: 0 }}
                  >
                    <ListItemButton
                      className={`lst-itm ${isSelected ? "Mui-selected" : ""}`}
                      onClick={() => navigate(item?.selected)}
                    >
                      <ListItemIcon>{item?.icon}</ListItemIcon>
                      <ListItemText
                        className="lstitm-txt"
                        primary={item?.label}
                      />
                    </ListItemButton>
                  </List>
                );
              })
          : null}
      </Drawer>
      <Drawer
        variant="permanent"
        className="sidebar_drawer"
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            width: SIDEBAR_WIDTH,
            boxSizing: "border-box",
          },
        }}
        open
      >
        <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
          <figure>
            <img
              src="/static/images/logo.png"
              alt=""
              onClick={() => navigate("/dashboard")}
            />
          </figure>
          <IconButton
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "white",
              display: { lg: "none" },
            }}
            onClick={handleSidebarToggle}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        {list?.length
          ? list
              .filter((ele: any) => ele !== undefined)
              .map((item: any) => {
                const isSelected = location.pathname.includes(item?.selected);
                return (
                  <List
                    key={item?.label}
                    className="sidebr-lst"
                    sx={{ padding: 0 }}
                  >
                    <ListItemButton
                      className={`lst-itm ${isSelected ? "Mui-selected" : ""}`}
                      onClick={() => navigate(item?.selected)}
                    >
                      <ListItemIcon>{item?.icon}</ListItemIcon>
                      <ListItemText
                        className="lstitm-txt"
                        primary={item?.label}
                      />
                    </ListItemButton>
                  </List>
                );
              })
          : null}
      </Drawer>
    </Box>
  );
}

//   const drawer = (
//     <>
//       <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
//         <figure>
//           <img
//             src="/static/images/logo.png"
//             alt=""
//             onClick={() => navigate("/dashboard")}
//           />
//         </figure>
//         <IconButton
//           sx={{
//             position: "absolute",
//             top: "10px",
//             right: "10px",
//             color: "white",
//             display: { lg: "none" },
//           }}
//           onClick={handleSidebarToggle}
//         >
//           <CloseIcon />
//         </IconButton>
//       </Toolbar>

//       <List sx={{ flexGrow: 1 }} className="sidebr-lst">
//         <ListItemButton
//           className="lst-itm"
//           selected={location.pathname === "/dashboard"}
//           onClick={() => navigate("/dashboard")}
//         >
//           <ListItemIcon>
//             <DashboardIcon />
//           </ListItemIcon>
//           <ListItemText className="lstitm-txt" primary="Dashboard" />
//         </ListItemButton>

//         <ListItemButton
//           className="lst-itm"
//           selected={location.pathname === "/manageusers"}
//           onClick={() => navigate("/manageusers")}
//         >
//           <ListItemIcon>
//             <PeopleIcon />
//           </ListItemIcon>
//           <ListItemText className="lstitm-txt" primary="Manage Users" />
//         </ListItemButton>

//         <ListItemButton
//           className="lst-itm"
//           selected={location.pathname === "/manage-advertisers"}
//           onClick={() => navigate("/manage-advertisers")}
//         >
//           <ListItemIcon>
//             <Person3Icon />
//           </ListItemIcon>
//           <ListItemText className="lstitm-txt" primary="Manage Advertisers" />
//         </ListItemButton>
//         <ListItemButton
//           className="lst-itm"
//           selected={location.pathname === "/manage-advertisements"}
//           onClick={() => navigate("/manage-advertisements")}
//         >
//           <ListItemIcon>
//             <FeaturedVideoIcon />
//           </ListItemIcon>
//           <ListItemText
//             className="lstitm-txt"
//             primary="Manage Advertisements"
//           />
//         </ListItemButton>
//         <ListItemButton
//           className="lst-itm"
//           selected={location.pathname === "/manage-content"}
//           onClick={() => navigate("/manage-content")}
//         >
//           <ListItemIcon>
//             <ContentPasteIcon />
//           </ListItemIcon>
//           <ListItemText className="lstitm-txt" primary="Manage Content/Posts" />
//         </ListItemButton>

//         <ListItemButton
//           className="lst-itm"
//           selected={location.pathname === "/manage-categories"}
//           onClick={() => navigate("/manage-categories")}
//         >
//           <ListItemIcon>
//             <ListAltIcon />
//           </ListItemIcon>
//           <ListItemText
//             className="lstitm-txt"
//             primary="Manage Challenge Categories "
//           />
//         </ListItemButton>
//         <ListItemButton
//           className="lst-itm"
//           selected={location.pathname === "/manage-subscription"}
//           onClick={() => navigate("/manage-subscription")}
//         >
//           <ListItemIcon>
//             <SubscriptionsIcon />
//           </ListItemIcon>
//           <ListItemText
//             className="lstitm-txt"
//             primary="Manage Subscription Plans"
//           />
//         </ListItemButton>

//         <ListItemButton
//           className="lst-itm"
//           selected={location.pathname === "/manage-rewards"}
//           onClick={() => navigate("/manage-rewards")}
//         >
//           <ListItemIcon>
//             <EmojiEventsIcon />
//           </ListItemIcon>
//           <ListItemText className="lstitm-txt" primary="Manage Rewards " />
//         </ListItemButton>
//         <ListItemButton
//           className="lst-itm"
//           selected={location.pathname === "/customer-support"}
//           onClick={() => navigate("/customer-support")}
//         >
//           <ListItemIcon>
//             <HelpCenterIcon />
//           </ListItemIcon>
//           <ListItemText className="lstitm-txt" primary="Customer Support" />
//         </ListItemButton>
     
//         <ListItemButton
//           className="lst-itm"
//           selected={location.pathname === "/manage-challenges"}
//           onClick={() => navigate("/manage-challenges")}
//         >
//           <ListItemIcon>
//             <ThumbUpIcon />
//           </ListItemIcon>
//           <ListItemText className="lstitm-txt" primary="Manage Challenges" />
//         </ListItemButton>

//         <ListItemButton
//           className="lst-itm"
//           selected={location.pathname === "/manage-words"}
//           onClick={() => navigate("/manage-words")}
//         >
//           <ListItemIcon>
//             <LockClockIcon />
//           </ListItemIcon>
//           <ListItemText className="lstitm-txt" primary="Manage Words" />
//         </ListItemButton>

//         <ListItemButton
//           className="lst-itm"
//           selected={location.pathname === "/manage-revenue"}
//           onClick={() => navigate("/manage-revenue")}
//         >
//           <ListItemIcon>
//             <PaidIcon />
//           </ListItemIcon>
//           <ListItemText className="lstitm-txt" primary="Manage Payments" />
//         </ListItemButton>

//         <ListItemButton
//           className="lst-itm"
//           selected={location.pathname === "/manage-notifications"}
//           onClick={() => navigate("/manage-notifications")}
//         >
//           <ListItemIcon>
//             <NotificationsActiveIcon />
//           </ListItemIcon>
//           <ListItemText className="lstitm-txt" primary="Manage Notifications" />
//         </ListItemButton>
//         <ListItemButton
//           className="lst-itm"
//           selected={location.pathname === "/analytics"}
//           onClick={() => navigate("/analytics")}
//         >
//           <ListItemIcon>
//             <BarChartIcon />
//           </ListItemIcon>
//           <ListItemText
//             className="lstitm-txt"
//             primary="Reports and Analytics"
//           />
//         </ListItemButton>
//         <ListItemButton
//           className="lst-itm"
//           selected={location.pathname === "/manage-subAdmin"}
//           onClick={() => navigate("/manage-subAdmin")}
//         >
//           <ListItemIcon>
//             <PersonAddIcon />
//           </ListItemIcon>
//           <ListItemText className="lstitm-txt" primary="Manage Sub-Admin" />
//         </ListItemButton>
//         <ListItemButton
//           className="lst-itm"
//           selected={location.pathname === "/cms"}
//           onClick={() => navigate("/cms")}
//         >
//           <ListItemIcon>
//             <SummarizeTwoToneIcon />
//           </ListItemIcon>
//           <ListItemText className="lstitm-txt" primary="Manage CMS" />
//         </ListItemButton>
//         <ListItemButton
//           className="lst-itm"
//           selected={location.pathname === "/faq"}
//           onClick={() => navigate("/faq")}
//         >
//           <ListItemIcon>
//             <QuizIcon />
//           </ListItemIcon>
//           <ListItemText className="lstitm-txt" primary=" Manage FAQs" />
//         </ListItemButton>
//       </List>
//     </>
//   );

//   return (
//     <Box
//       component="nav"
//       sx={{ flexShrink: { lg: 0 } }}
//       className="sidebar-main-div"
//     >
//       <Drawer
//         className="sidebar_drawer"
//         variant="temporary"
//         open={mobileOpen}
//         onClose={handleSidebarToggle}
//         ModalProps={{
//           keepMounted: true,
//         }}
//         sx={{
//           display: { xs: "block", lg: "none" },
//           "& .MuiDrawer-paper": {
//             boxSizing: "border-box",
//             width: SIDEBAR_WIDTH,
//           },
//         }}
//       >
//         {drawer}
//       </Drawer>
//       <Drawer
//         variant="permanent"
//         className="sidebar_drawer"
//         sx={{
//           display: { xs: "none", lg: "block" },
//           "& .MuiDrawer-paper": {
//             width: SIDEBAR_WIDTH,
//             boxSizing: "border-box",
//           },
//         }}
//         open
//       >
//         {drawer}
//       </Drawer>
//     </Box>
//   );
// }
