import {
  Box,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { Dispatch, SetStateAction, useState } from "react";
import SearchBar from "../../components/SearchBar";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useNavigate } from "react-router-dom";
import { AdvertiserResponse } from "../../types/General";
import { isValidInput } from "../../utils/validations";
import { Pagination } from "../../components";
import WarnModal from "../../components/WarnModal";
import { handleDelete, updateStatus } from "../../utils/commonFunctions";
import {
  useLazyChangeAdvertiserStatusQuery,
  useLazyDeleteAdvertiserByIdQuery,
} from "../../services/advertiser";
import { Permissions } from "../../types/User";
import useAuth from "../../hooks/useAuth";
import useTranslation from "../../hooks/Translation";

type props = {
  advertisers: AdvertiserResponse[];
  setAdvertisers: Dispatch<SetStateAction<AdvertiserResponse[]>>;
  setDebouncedSearchTerm: Dispatch<SetStateAction<string>>;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  page: number;
  totalPages: number;
  onPageChange: (newPage: number) => void;
  getAdvertisersList: () => void;
  handleExportCsv: () => void;
  hidePermission: Permissions | null | undefined;
};

const Rejected = ({
  advertisers,
  setAdvertisers,
  setDebouncedSearchTerm,
  searchTerm,
  setSearchTerm,
  page,
  totalPages,
  onPageChange,
  getAdvertisersList,
  handleExportCsv,
  hidePermission,
}: props) => {
  const navigate = useNavigate();
  const userData = useAuth();
  const [selectedId, setSelectedId] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);

  // const [updateAdvertiserStatus] = useLazyChangeAdvertiserStatusQuery();
  const [deleteById] = useLazyDeleteAdvertiserByIdQuery();

  const label = { inputProps: { "aria-label": "Switch demo" } };
  const translation=useTranslation()as any

  return (
    <div>
      <Box className="cards_header">
        <SearchBar
          searchTerm={searchTerm}
          setDebouncedSearchTerm={setDebouncedSearchTerm}
          value={searchTerm}
          onCross={() => setSearchTerm("")}
          onChange={(val: any) => {
            if (isValidInput(val.target.value)) {
              setSearchTerm(val.target.value);
            }
          }}
        />
        <Box className="cards_header_right">
          <Button className="btn btn_primary" onClick={handleExportCsv}>
            <FileDownloadIcon /> {translation.Globals.export_csv||""}
          </Button>
          {hidePermission?.isEdit || userData?.role === "ADMIN" ? (
            <Button
              className="btn btn_primary"
              onClick={() => navigate("/manage-advertisers/add")}
            >
              {translation.manage_advertisers.add_advertisers||""}
            </Button>
          ) : null}
        </Box>
      </Box>
      <TableContainer className="table_container">
        <Box className="heading"></Box>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">{translation.table_heads.sno||""}</TableCell>
              <TableCell align="center">{translation.table_heads.fname||""}</TableCell>
              <TableCell align="center">{translation.table_heads.contact_number||""}</TableCell>
              <TableCell align="center">{translation.table_heads.email||""}</TableCell>
              {/* <TableCell align="center">Account Status</TableCell> */}
              <TableCell align="center">{translation.table_heads.actions||""}</TableCell>
            </TableRow>
          </TableHead>

          {advertisers?.length ? (
            advertisers?.map((item, i) => {
              return (
                <TableBody key={item?._id}>
                  <TableRow>
                    <TableCell align="center">
                      {" "}
                      {(page - 1) * 10 + i + 1}
                    </TableCell>

                    <TableCell>{item?.fullName || "-"}</TableCell>

                    <TableCell>
                      {" "}
                      {item?.countryCode
                        ? (item?.countryCode.includes("+") ? "" : "+") +
                          item?.countryCode
                        : null}{" "}
                      {item?.phone || "-"}
                    </TableCell>
                    <TableCell>{item?.email || "-"}</TableCell>

                    {/* <TableCell align="center">
                      <Switch
                        {...label}
                        size="small"
                        checked={!item?.isBlocked}
                        onChange={() =>
                          updateStatus(
                            item?._id,
                            !item?.isBlocked,
                            updateAdvertiserStatus,
                            setAdvertisers,
                            advertisers
                          )
                        }
                      />
                    </TableCell> */}
                    <TableCell>
                      <Box className="table_actions">
                        <IconButton
                          onClick={() =>
                            navigate(
                              `/manage-advertisers/details/${item?._id}`,
                              {
                                state: { userStatus: "REJECTED" },
                              },
                            )
                          }
                        >
                          <VisibilityIcon />
                        </IconButton>
                        {hidePermission?.isEdit ||
                        userData?.role === "ADMIN" ? (
                          <IconButton
                            onClick={() =>
                              navigate(`/manage-advertisers/edit/${item?._id}`)
                            }
                          >
                            <ModeEditIcon />
                          </IconButton>
                        ) : null}
                        {hidePermission?.isDelete ||
                        userData?.role === "ADMIN" ? (
                          <IconButton>
                            <DeleteIcon
                              onClick={() => {
                                setOpen(true);
                                setSelectedId(item?._id);
                              }}
                            />
                          </IconButton>
                        ) : null}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              );
            })
          ) : (
            <TableBody>
              <TableCell align="center" colSpan={10} sx={{ color: "#051140" }}>
                No Advertisers Found
              </TableCell>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Pagination
        module={advertisers}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
      <WarnModal
        setOpen={setOpen}
        open={open}
        name="advertiser"
        handleDelete={() =>
          handleDelete(deleteById, selectedId, getAdvertisersList)
        }
      />
    </div>
  );
};

export default Rejected;
