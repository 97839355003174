import { END_POINTS } from "../constants/url";
import { AdvertiserResponse, WordResponse } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type GetTokenParams = {
  limit?: number;
  page?: number;
  query?: string;
};

type GetAllAdvertisersResponse = {
  words: WordResponse[];
  count: number;
};

type AddWordBody = {
  name: string;
  name_ar: string;
};

export const wordApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postWord: builder.mutation<CommonResponseType, AddWordBody>({
      query: (body) => ({
        url: `${END_POINTS.word}`,
        method: "POST",
        body,
      }),
    }),

    getAllWords: builder.query<
      CommonResponseType & { data: GetAllAdvertisersResponse },
      GetTokenParams
    >({
      query: ({ page, limit, query }) => ({
        url: `${END_POINTS.word}?page=${page}&limit=${limit}&search=${query}`,
        method: "GET",
      }),
    }),
    getWordsById: builder.query<
      CommonResponseType & { data: WordResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.word}/${id}`,
        method: "GET",
      }),
    }),
    changeWordStatus: builder.query<
      CommonResponseType & { data: WordResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.wordStatusChange}/${id}`,
        method: "GET",
      }),
    }),

    editWordById: builder.mutation<
      CommonResponseType & { data: WordResponse },
      { id: string; body: AddWordBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.word}/${id}`,
        method: "PUT",
        body,
      }),
    }),

    deleteWordById: builder.query<
      CommonResponseType & { data: WordResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.word}/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  usePostWordMutation,
  useLazyGetAllWordsQuery,
  useLazyGetWordsByIdQuery,
  useLazyChangeWordStatusQuery,
  useLazyDeleteWordByIdQuery,
  useEditWordByIdMutation,
} = wordApi;
