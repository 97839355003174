import VisibilityIcon from "@mui/icons-material/Visibility";

import moment from "moment";

import {
  Box,
  Card,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";

import { PostResponse, ReportedPosts } from "../../types/General";
import { useNavigate } from "react-router-dom";
import { Pagination } from "../../components";
import { useEffect, useState } from "react";
import useTranslation from "../../hooks/Translation";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

type props = {
  userPosts: PostResponse[];
  reportedPosts: ReportedPosts[];
  page: number;
  totalPages: number;
  onPageChange: (newPage: number) => void;
  getPosts: (id: string) => void;
  getReportedPostsList: (id: string) => void;
  userId: string | undefined;
};

const PostsAndStories = ({
  getPosts,
  getReportedPostsList,
  userPosts,
  reportedPosts,
  page,
  totalPages,
  userId,
  onPageChange,
}: props) => {
  const navigate = useNavigate();

  const [value, setValue] = useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (userId) {
      if (value === 0) {
        getPosts(userId);
      } else if (value === 1) {
        getReportedPostsList(userId);
      }
    }
  }, [value, page]);
  const translation=useTranslation()as any

  return (
    <Card sx={{ mt: 4 }} className="cards">
      <Box className="custom_tabs">
        <Box className="flx_sc">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="basic tabs example"
            className="custom_tabs_links"
          >
            <Tab label="All Posts" {...a11yProps(0)} />
            <Tab label="Reported Posts" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">{translation.table_heads.sno||""}</TableCell>
                  <TableCell>{translation.table_heads.post||""}</TableCell>
                  <TableCell>{translation.table_heads.post_description||""}</TableCell>
                  <TableCell>{translation.table_heads.date_time||""}</TableCell>
                  <TableCell>{translation.table_heads.actions||""}</TableCell>
                </TableRow>
              </TableHead>

              {userPosts?.length ? (
                userPosts?.map((item, i) => {
                  return (
                    <TableBody key={item?._id}>
                      <TableRow>
                        <TableCell align="center">
                          {(page - 1) * 10 + i + 1}
                        </TableCell>
                        <TableCell>
                          {item?.uploads[0]?.type === "IMAGE" ? (
                            <figure className="bnr_img">
                              <img
                                src={
                                  item?.uploads[0]?.link ||
                                  "/static/images/post.png"
                                }
                                alt=""
                              />
                            </figure>
                          ) : (
                            <figure className="bnr_img">
                              <img
                                src={
                                  item?.uploads[0]?.thumbnail ||
                                  "/static/images/post.png"
                                }
                                alt=""
                              />
                            </figure>
                          )}
                        </TableCell>
                        <TableCell>
                          {item?.comment?.length > 30
                            ? item?.comment.slice(0, 30) + "..."
                            : item?.comment || ""}
                        </TableCell>

                        <TableCell>
                          <Typography variant="h6" fontSize={14}>
                            {moment(item?.createdAt).format("DD/MM/YYYY")}
                          </Typography>

                          <Typography variant="h6" fontSize={14}>
                            {moment(item?.createdAt).format("h:mm A")}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Box className="table_actions">
                            <IconButton
                              onClick={() =>
                                navigate(
                                  `/manage-content/details/${item?._id}`,
                                  {
                                    state: { page: "USER" },
                                  }
                                )
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : (
                <TableBody>
                  <TableCell
                    align="center"
                    colSpan={10}
                    sx={{ color: "#051140" }}
                  >
                    No Posts Found
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Post </TableCell>
                  <TableCell>Post Description</TableCell>
                  <TableCell>Upload Date and Time</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>

              {reportedPosts?.length ? (
                reportedPosts?.map((item, i) => {
                  return (
                    <TableBody key={item?._id}>
                      <TableRow>
                        <TableCell align="center">
                          {(page - 1) * 10 + i + 1}
                        </TableCell>
                        <TableCell>
                          {item?.post?.uploads[0]?.type === "IMAGE" ? (
                            <figure className="bnr_img">
                              <img
                                src={
                                  item?.post?.uploads[0]?.link ||
                                  "/static/images/post.png"
                                }
                                alt=""
                              />
                            </figure>
                          ) : (
                            <figure className="bnr_img">
                              <img
                                src={
                                  item?.post?.uploads[0]?.thumbnail ||
                                  "/static/images/post.png"
                                }
                                alt=""
                              />
                            </figure>
                          )}
                        </TableCell>
                        <TableCell>
                          {item?.post?.comment?.length > 30
                            ? item?.post?.comment.slice(0, 30) + "..."
                            : item?.post?.comment || ""}
                        </TableCell>

                        <TableCell>
                          <Typography variant="h6" fontSize={14}>
                            {moment(item?.createdAt).format("DD/MM/YYYY")}
                          </Typography>

                          <Typography variant="h6" fontSize={14}>
                            {moment(item?.createdAt).format("h:mm A")}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Box className="table_actions">
                            <IconButton
                              onClick={() =>
                                navigate(
                                  `/manage-content/details/${item?._id}`,
                                  {
                                    state: { page: "USER" },
                                  }
                                )
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : (
                <TableBody>
                  <TableCell
                    align="center"
                    colSpan={10}
                    sx={{ color: "#051140" }}
                  >
                    No Posts Found
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </CustomTabPanel>
      </Box>
      <Pagination
        module={userPosts}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
    </Card>
  );
};

export default PostsAndStories;
