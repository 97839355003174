import React, { useEffect } from "react";
import "./App.scss";
import Routing from "./Routes";
import LanguageContextProvider from "./context/LanguageContext";
import { toast } from "react-toastify";
import { firebaseCloudMessaging } from "./utils/firebase";

function App() {
  useEffect(() => {
    async function setToken() {
      try {
        const fcmToken = await firebaseCloudMessaging.init();
        if (fcmToken) {
          console.log("fcm_Token", fcmToken);
        }
      } catch (error) {
        console.log(error);
      }
    }
    setToken();

    // const playNotificationSound = () => {
    //   const audio = new Audio("/static/sounds/notification_sound.mp3"); // Specify the path to your notification sound
    //   audio.play();
    // };

    // Event listener that listens for the push notification event in the background
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        try {
          event?.source?.postMessage("Hi client");
          console.log("event for the service worker", event);
          // playNotificationSound();
          return toast(
            <div style={{ height: "100%" }}>
              <div
                style={{ color: "#820000" }}
                // onClick={() => navigate("/notifications")}
              >
                {event?.data?.data?.title}
              </div>
              <div
                style={{ color: "#1d1d1d", paddingTop: "10px" }}
                // onClick={() => navigate("/notifications")}
              >
                {event?.data?.data?.message}
              </div>
            </div>
          );
          return event;
        } catch (e) {
          console.log(e, "error");
          return e;
        }
      });
    }

    if ("permissions" in navigator) {
      navigator.permissions
        .query({ name: "notifications" })
        .then(function (notificationPerm) {
          notificationPerm.onchange = function () {
            if (notificationPerm.state === "granted") {
              setToken();
            }
          };
        });
    }
    return () => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.removeEventListener("message", (e) =>
          console.log(e)
        );
      }
    };
  }, []);
  return (
    <LanguageContextProvider>
      <Routing />
    </LanguageContextProvider>
  );
}

export default App;
