import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Modal,
  Paper,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import MainContainer from "../../layout/MainContainer";
import { useLazyGetStoryByIdQuery } from "../../services/posts";
import { Loader, showError } from "../../constants";
import { Comments, PostResponse, StoryResponse } from "../../types/General";
import { DocumentViewer } from "../../components";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StoryDetails = () => {
  const location = useLocation();
  const { state } = location;
  const { id } = useParams();

  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = React.useState(0);
  const [postDetail, setPostDetail] = useState<StoryResponse>();
  const [comments, setComments] = useState<Comments[]>([]);
  const [reportComments, setReportComments] = useState<Comments[]>([]);
  const [uploadPreview, setUploadPreview] = useState<string | undefined>("");
  const [type, setType] = useState<string | undefined>("");
  const [modalOpen, setModalOpen] = useState(false);

  const [getStoryById, { isLoading }] = useLazyGetStoryByIdQuery();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const getStoryDetail = async (id: string) => {
    try {
      const response = await getStoryById({
        id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setPostDetail(response?.data?.story[0]);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const handlePopup = (image: string | undefined, type: string | undefined) => {
    setOpen(true);
    setUploadPreview(image);
    setType(type);
  };

  useEffect(() => {
    if (id) {
      getStoryDetail(id);
    }
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View Story Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => navigate("/manageusers")}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography
                  className="detail_title mn_hdng"
                  component="h2"
                  mb={3}
                >
                  User Details
                </Typography>
              </Grid>
              {/* <Grid item lg={2} md={2} sm={6} xs={12}>
                <figure className="profile_img">
                  <img src="/static/images/user_placeholder.png" alt="" />
                </figure>
              </Grid> */}
              <Grid item xs={12} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Username</Typography>
                      <Typography component="p">
                        {postDetail?.userName || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  {/* <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Phone no</Typography>
                      <Typography component="p">
                        {" "}
                        {postDetail?.userCountryCode
                          ? (postDetail?.userCountryCode.includes("+")
                              ? ""
                              : "+") + postDetail?.userCountryCode
                          : null}{" "}
                        {postDetail?.userPhone || "-"}
                      </Typography>
                    </Box>
                  </Grid> */}
                  {/* <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Email</Typography>
                      <Typography component="p">
                        {postDetail?.userEmail || "-"}
                      </Typography>
                    </Box>
                  </Grid> */}

                  <Grid item mt={3} xs={12} className="view_box_list">
                    <Typography
                      className="detail_title mn_hdng"
                      component="h2"
                      mb={3}
                    >
                      Content Details
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box>
                          <Typography component="h5">Uploads</Typography>
                          <Box className="post_div">
                            {/* {postDetail?.uploads?.length
                              ? postDetail?.uploads?.map((item) => {
                                  return (
                                    <> */}
                            {postDetail?.type === "IMAGE" ? (
                              <figure
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handlePopup(
                                    postDetail?.uploads,
                                    postDetail?.type
                                  )
                                }
                              >
                                <img src={postDetail?.uploads || ""} alt="" />
                              </figure>
                            ) : (
                              <video
                                style={{ cursor: "pointer" }}
                                autoPlay
                                // muted
                                loop
                                playsInline
                                className="hm_vd"
                                preload="yes"
                                onClick={() =>
                                  handlePopup(
                                    postDetail?.uploads,
                                    postDetail?.type
                                  )
                                }
                              >
                                <source
                                  src={postDetail?.uploads || ""}
                                  type="video/mp4"
                                />
                              </video>
                            )}
                            {/* </>
                                  );
                                })
                              : null} */}
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box>
                          <Typography component="h5">Description</Typography>
                          <Typography component="p">
                            {" "}
                            {/* {postDetail?.comment || "-"} */}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Upload Date</Typography>
                          <Typography component="p">
                            {" "}
                            {moment(postDetail?.createdAt).format("DD/MM/YYYY")}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Upload Time</Typography>
                          <Typography component="p">
                            {moment(postDetail?.createdAt).format("h:mm A")}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <DocumentViewer
          open={open}
          setOpen={setOpen}
          image={uploadPreview}
          type={type}
        />
      </div>
    </MainContainer>
  );
};

export default StoryDetails;
