import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Card,
  IconButton,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import {
  Journey,
  PostResponse,
  ReportedJourney,
  ReportedPosts,
} from "../../types/General";
import {
  useLazyChangePostStatusQuery,
  useLazyDeletePostByIdQuery,
  useLazyDeleteReportedByIdQuery,
  useLazyGetPostsQuery,
  useLazyGetReportedPostsQuery,
} from "../../services/posts";
import { Loader, showError } from "../../constants";

import moment from "moment";
import { handleDelete, updateStatus, updateStatusNew } from "../../utils/commonFunctions";
import { Pagination } from "../../components";
import WarnModal from "../../components/WarnModal";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";
import {
  useLazyDeleteJourneyQuery,
  useLazyGetJourneyQuery,
  useLazyJourneyStatusChangeQuery,
  useLazyReportJourneyQuery,
} from "../../services/advertiser";
import { isValidInput } from "../../utils/validations";
import SearchBar from "../../components/SearchBar";
import useTranslation from "../../hooks/Translation";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageContent = () => {
  const navigate = useNavigate();
  const userData = useAuth();

  const [selectedId, setSelectedId] = useState<string>("");
  const [value, setValue] = useState<number>(0);

  const [totalCount, setTotalCount] = useState<number>(0);

  const [open, setOpen] = useState<boolean>(false);
  const [journey, setJourney] = useState<Journey[]>();
  const [page, setPage] = useState<number>(1);
  const [posts, setPosts] = useState<PostResponse[]>([]);
  const [reportedJOurney, setReportedJourney] = useState<ReportedJourney[]>();
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  // const [getPosts, { isLoading }] = useLazyGetPostsQuery();
  const [getReportedJourney] = useLazyReportJourneyQuery();
  const [deleteJourney] = useLazyDeleteJourneyQuery();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");

  const [deleteReportedPost] = useLazyDeleteReportedByIdQuery();

  const label = { inputProps: { "aria-label": "Switch demo" } };

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const checkPermission = () => {
    const permission = userData?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Journey"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  const [getJourney, { isLoading }] = useLazyGetJourneyQuery();
  const [statusJourney] = useLazyJourneyStatusChangeQuery();
  const getUserJourney = async () => {
    try {
      const response = await getJourney({
        search: debouncedSearchTerm.trim(),
        limit: 10,
        page: page,
      });
      console.log(response?.data, "journey");
      if (response?.data?.statusCode === 200) {
        setJourney(response?.data?.data?.journey);
        setTotalCount(response?.data?.data?.journeyCount);
      } else {
        setJourney([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  console.log(journey, "seedgfds");

  const getReportedJourneyList = async () => {
    try {
      const response = await getReportedJourney({
        search: debouncedSearchTerm.trim(),
        page: page,
        limit: 10,
      }).unwrap();
      console.log(response, "lkj");
      if (response?.statusCode === 200) {
        setReportedJourney(response?.data?.reportJourney || []);
        setTotalCount(response?.data?.count);
      } else {
        setReportedJourney([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (value === 0) {
      getUserJourney();
    } else {
      getReportedJourneyList();
    }
  }, [page, value, debouncedSearchTerm, searchTerm]);

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);
  const translations = useTranslation() as any;
  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">
            {translations.manage_journey.manage_journey || ""}
          </h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="flx_sc">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab
                  label={translations.manage_journey.manage_journey || ""}
                  {...a11yProps(0)}
                />
                <Tab
                  label={translations.manage_journey.reported_journey || ""}
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Box className="cards_header">
                <SearchBar
                  searchTerm={searchTerm}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  value={searchTerm}
                  onCross={() => setSearchTerm("")}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
              </Box>
              <TableContainer className="table_container">
                {/* <Box className="heading"></Box> */}
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        {translations.table_heads.sno || ""}
                      </TableCell>
                      <TableCell>
                        {translations.table_heads.journey || ""}
                      </TableCell>
                      <TableCell>
                        {translations.table_heads.journey_desc || ""}
                      </TableCell>
                      <TableCell>
                        {translations.table_heads.username || ""}
                      </TableCell>
                      <TableCell>
                        {translations.table_heads.upload_date || ""}
                      </TableCell>
                      {/* <TableCell>Upload Time</TableCell> */}
                      <TableCell>
                        {translations.table_heads.status || ""}
                      </TableCell>
                      <TableCell>
                        {translations.table_heads.actions || ""}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {journey?.length ? (
                    journey?.map((item: any, index: any) => (
                      <TableBody>
                        <TableRow>
                          <TableCell>{(page - 1) * 10 + index + 1}</TableCell>
                          <TableCell>
                            {item?.title.length > 10
                              ? `${item.title.substring(0, 15)}...`
                              : item?.title}
                          </TableCell>
                          <TableCell>
                            {item?.description.length > 10
                              ? `${item.description.substring(0, 15)}...`
                              : item?.description}
                          </TableCell>
                          <TableCell>
                            <p>{item?.userName}</p>
                            <p>{item?.email}</p>
                          </TableCell>
                          <TableCell>
                            {moment(item?.createdAt).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell>
                            <Switch
                              onChange={() => {
                                updateStatus(
                                  item?._id,
                                  !item?.isBlocked,
                                  statusJourney,
                                  setJourney,
                                  journey
                                );
                              }}
                              {...label}
                              checked={!item?.isBlocked}
                              size="small"
                            />
                          </TableCell>
                          <TableCell>
                            <Box className="table_actions">

                              
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    "/manage-journeys/details/" + item?._id,
                                    {
                                      state: { page: "Journey" },
                                    }
                                  )
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>

                              {hidePermission?.isDelete ||
                              userData?.role === "ADMIN" ? (
                                <IconButton
                                  onClick={() => {
                                    setOpen(true);
                                    setSelectedId(item?._id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              ) : null}
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))
                  ) : (
                    <TableBody>
                      <TableCell
                        align="center"
                        colSpan={10}
                        sx={{ color: "#051140" }}
                      >
                        {translations.manage_journey.not_found || ""}
                      </TableCell>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Box className="cards_header">
                <SearchBar
                  searchTerm={searchTerm}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  value={searchTerm}
                  onCross={() => setSearchTerm("")}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
              </Box>
              <TableContainer className="table_container">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        {translations.table_heads.sno || ""}
                      </TableCell>
                      <TableCell>
                        {translations.table_heads.journey || ""}
                      </TableCell>
                      <TableCell>
                        {translations.table_heads.journey_desc || ""}
                      </TableCell>
                      <TableCell>
                        {translations.table_heads.reported_by || ""}
                      </TableCell>
                      <TableCell>
                        {translations.table_heads.date_time || ""}
                      </TableCell>
                      <TableCell>
                        {translations.table_heads.status || ""}
                      </TableCell>
                      <TableCell>
                        {translations.table_heads.actions || ""}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {reportedJOurney?.length ? (
                    reportedJOurney?.map((item: any, index: any) => (
                      <TableBody>
                        <TableRow>
                          <TableCell>{(page - 1) * 10 + index + 1}</TableCell>
                          <TableCell>
                            {item?.title.length > 10
                              ? `${item.title.substring(0, 15)}...`
                              : item?.title}
                          </TableCell>
                          <TableCell>
                            {item?.description.length > 10
                              ? `${item.description.substring(0, 15)}...`
                              : item?.description}
                          </TableCell>
                          <TableCell>
                            <p>{item?.userName}</p>
                            <p>{item?.email}</p>
                          </TableCell>
                          <TableCell>
                            {moment(item?.createdAt).format("DD/MM/YYYY")}
                          </TableCell>

                          <TableCell>
                            <Switch
                              onChange={() => {
                                updateStatusNew(
                                  item?.journeyId,
                                  item?._id,
                                  !item?.isBlocked,
                                  statusJourney,
                                  setReportedJourney,
                                  reportedJOurney,
                                );
                              }}
                              {...label}
                              checked={!item?.isBlocked}
                              size="small"
                            />
                          </TableCell>

                          <TableCell>
                            <Box className="table_actions">
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    "/manage-journeys/details/" +
                                      item?.journeyId,
                                    {
                                      state: { page: "Journey" },
                                    }
                                  )
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>

                              {hidePermission?.isDelete ||
                              userData?.role === "ADMIN" ? (
                                <IconButton
                                  onClick={() => {
                                    setOpen(true);
                                    setSelectedId(item?._id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              ) : null}
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))
                  ) : (
                    <TableBody>
                      <TableCell
                        align="center"
                        colSpan={10}
                        sx={{ color: "#051140" }}
                      >
                        {translations.manage_journey.not_found || ""}
                      </TableCell>
                    </TableBody>
                  )}

                  {/* <TableBody>
                    <TableCell
                      align="center"
                      colSpan={10}
                      sx={{ color: "#051140" }}
                    >
                      No Journey Found
                    </TableCell>
                  </TableBody> */}
                </Table>
              </TableContainer>
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
      <Pagination
        module={value === 0 ? journey : reportedJOurney}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
      {value === 0 ? (
        <WarnModal
          setOpen={setOpen}
          open={open}
          name=  {translations.table_heads.journey || ""}
          handleDelete={() =>
            handleDelete(deleteJourney, selectedId, getUserJourney)
          }
        />
      ) : (
        <WarnModal
          setOpen={setOpen}
          open={open}
          name= {translations.manage_journey.reported_journey || ""}
          handleDelete={() =>
            handleDelete(deleteReportedPost, selectedId, getReportedJourneyList)
          }
        />
      )}
    </MainContainer>
  );
};

export default ManageContent;
