import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  Input,
  CardMedia,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { Autocomplete } from "@react-google-maps/api";
import { useNavigate, useParams } from "react-router-dom";
import * as YUP from "yup";
import { useFormik } from "formik";
import { ChangeEvent, useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import Multiselect from "multiselect-react-dropdown";
import {
  useLazyGetCategoryByIdQuery,
  useLazyGetChallengeByIdQuery,
  useUpdateChallengeMutation,
} from "../../services/advertiser";
import { showError, showToast } from "../../constants";
import { Challenge, Entry } from "../../types/General";
import moment from "moment";
import { isString } from "../../utils/validations";
import { UploadMedia, UploadVideo } from "../../utils/mediaUpload";
import useTranslation from "../../hooks/Translation";

interface Option {
  id: number;
  value: string;
}

type ChallengeList = {
  id: string;
  name: string;
};
const AddChallenge = () => {
  const navigate = useNavigate();
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const { id } = useParams();
  const [challenge, setChallenge] = useState<Entry>();
  const [cId, setCid] = useState<string>("");
  const [image, setImage] = useState<string>("");
  const [video, setVideo] = useState<string>("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [type, setType] = useState("");
  const [fileName, setFileName] = useState<string>("");
  const [list, setList] = useState<ChallengeList[]>([{ id: "", name: "" }]);
  const [autocomplete, setAutocomplete] = useState(null);
  const [media, setMedia] = useState<string>("");
  const [messageType, setMessageType] = useState<string>("");
  const [categories, setCategories] = useState<any>();
  const translations = useTranslation() as any;
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    // Check if a file is selected
    if (file) {
      event.target.value = "";
      // Check if the selected file is an image
      if (file.type.startsWith("image/")) {
        // setLoading(true);
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          setType("IMAGE");
          setMedia(res?.data);
          // setMessageType(SOCKET_KEYS.image);
          // setLoading(false);
        } else {
          // setLoading(false);
          showError(res?.message);
        }
      } else if (file.type.startsWith("video/")) {
        // setLoading(true);
        const res = await UploadVideo(file);
        if (res?.statusCode === 200) {
          setType("VIDEO");
          setMedia(res?.data);
          // setMessageType(SOCKET_KEYS.video);
          // setLoading(false);
        } else {
          showError(res?.message);
          // setLoading(false);
        }
      } else {
        showError(translations.Globals.select_img);
        // setLoading(false);
      }
    }
  };

  const [file, setFile] = useState<string | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  console.log(media, "media");
  const onLoad = (autocompleteObj: any) => {
    setAutocomplete(autocompleteObj);
  };

  const onPlaceChanged = async () => {
    if (autocomplete) {
      let place = await (autocomplete as any).getPlace();
      console.log(place, "placde");

      if (place && place.address_components) {
        let address = place.address_components;

        let state,
          city,
          zip = "";

        address.forEach(function (component: any) {
          let types = component.types;

          if (types.indexOf("locality") > -1) {
            city = component.long_name;
          }
          if (types.indexOf("postal_code") > -1) {
            zip = component.long_name;
          }
          if (types.indexOf("administrative_area_level_1") > -1) {
            state = component?.long_name || "";
          }
          if (types.indexOf("country") > -1) {
            state = component?.long_name || "";
          }
        });

        var lat = place.geometry.location.lat();
        var lng = place.geometry.location.lng();

        formik.setFieldValue("location", `${place?.formatted_address}`);
        formik.setFieldValue("city", city || "");
        formik.setFieldValue("zipCode", zip || "");
        formik.setFieldValue("latitude", lat || "");
        formik.setFieldValue("longitude", lng || "");
        formik.setFieldValue("state", state || "");
      }
    }
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = (event.target as HTMLInputElement).files;
    if (files && files[0]) {
      const reader = new FileReader();

      reader.onload = () => {
        const fileDataURL = reader.result as string;
        setFile(fileDataURL);
      };

      reader.readAsDataURL(files[0]);
    }
  };

  const handleCancel = () => {
    setMedia("");
  };

  const [getChallenges, { isLoading }] = useLazyGetChallengeByIdQuery();
  const [getCategory] = useLazyGetCategoryByIdQuery();
  const [updateChallenge] = useUpdateChallengeMutation();

  const fetchCategory = async () => {
    try {
      const response: any = await getCategory({}).unwrap();
      if (response?.statusCode === 200) {
        setCategories(response?.data?.categories);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };
  const fetchChallenges = async (id: string | undefined) => {
    try {
      const response: any = await getChallenges({
        id: id,
      }).unwrap();
      console.log(response.data);
      if (response?.statusCode === 200) {
        setChallenge(response?.data);
        setCategory(response?.data?.categoryName);
        setMedia(response?.data?.uploads[0]?.link);
        setType(response?.data?.uploads[0]?.type);
        setList(response?.data?.challengeList);
        setCid(response?.data?.categoryId);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  const isImage = media && media.startsWith("data:image");
  const isVideo = media && media.startsWith("data:video/mp4");

  function createData(Module: string) {
    return { Module };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData("Manage Customers"),
    createData("Manage Points Criteria"),
    createData("Manage Round UP Configuration"),
    createData("Manage Subscription"),
    createData("Manage Email Template"),
    createData("Manage Notification"),
    createData("Reports and Analytics"),
    createData("Manage CMS"),
    createData("Manage Settings"),
  ];
  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
    const newItem = categories.find(
      (item: any) => item.name === event.target.value
    );
    console.log(newItem, "jjjj"); // Wrap console.log in an assignment or a function call
    setCid(newItem._id);

    // console.log(newItem,"mewejdhbfshjdf")
  };
  const handleSubCategoryChange = (event: SelectChangeEvent) => {
    setSubCategory(event.target.value as string);
  };
  const options: Option[] = [
    { id: 1, value: "Option 1" },
    { id: 2, value: "Option 2" },
    { id: 3, value: "Option 3" },
  ];
  const onSelect = (selectedList: Option[], selectedItem: Option) => {
    setSelectedOptions(selectedList);
  };
  const onRemove = (selectedList: Option[], removedItem: Option) => {
    setSelectedOptions(selectedList);
  };
  console.log(challenge?.noOfEntries, "ccccc");
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: challenge?.title || "",
      description: challenge?.description || "",
      startDate:
        moment.utc(challenge?.startTime).format("YYYY-MM-DD HH:mm") || "",
      endDate: moment.utc(challenge?.endTime).format("YYYY-MM-DDTHH:mm") || "",
      entries: challenge?.noOfEntries || "",
      location: challenge?.city,
      longitude: challenge?.longitude || "",
      latitude: challenge?.latitude || "",
      city: challenge?.city || "",
      state: challenge?.state || "",

      // zipCode:""
      // categories:challenge?.categoryName|| ""
    },

    validationSchema: YUP.object({
      title: YUP.string().required(translations.Globals.field_req),
      description: YUP.string().required(translations.Globals.field_req),
      entries: YUP.string().required(translations.Globals.field_req),
      startDate: YUP.string().required(translations.Globals.field_req),
      endDate: YUP.string().required(translations.Globals.field_req),
    }),

    onSubmit: async (values) => {
      if (!media) {
        showError(translations.Globals.img_vid);
        return;
      }
      console.log("hell");
      formik.setSubmitting(true);
      const body = {
        title: values.title,
        description: values.description,
        startTime: moment.utc(values.startDate).toISOString(),
        endTime: moment.utc(values.endDate).toISOString(),

        categoryName: category,
        noOfEntries: values.entries,
        latitude: values.latitude,
        longitude: values.longitude,
        categoryId: cId,
        challengeList: list,
        city: values.city,
        // zipCode:values.zipCode,
        state: values.state,
        uploads: [{ link: media, type: type, height: 4, width: 4 }],

        appKey: new Date().toISOString(),
      };

      console.log(body, "body");

      if (id) {
        try {
          const response: any = await updateChallenge({
            id,
            body,
          }).unwrap();
          console.log(response, "resssssssss");
          if (response?.statusCode === 200) {
            showToast(translations.Globals.update);
          }
        } catch (error: any) {
          showError(error?.data?.message);
        }
      }
    },
  });
  console.log(moment(challenge?.startTime).format("DD/MM/YYYY"), "dsdsdsd");
  console.log(formik.values.latitude, "latttt");

  useEffect(() => {
    if (id) {
      fetchChallenges(id);
    }
  }, []);
  useEffect(() => {
    fetchCategory();
  }, []);
  {
    console.log(list, "nnn");
  }
 
  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">
              {translations.manage_challenge.edit || ""}
            </h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-challenges");
              }}
            >
              {translations.Globals.back}
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Typography className="custom_label">
                      {translations.manage_challenge.upload || ""}
                    </Typography>
                    {media ? (
                      <div className="upload_image_preview2">
                        {type === "IMAGE" ? (
                          <CardMedia
                            component="img"
                            image={media}
                            alt="photo"
                          />
                        ) : (
                          <video
                            autoPlay
                            muted
                            loop
                            playsInline
                            className="video_dv"
                            preload="yes"
                          >
                            <source src={media} type="video/mp4" />
                          </video>
                        )}
                        <CancelIcon onClick={handleCancel} />
                      </div>
                    ) : (
                      <Box className="upload_image_bnr">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/*,video/*",
                            }}
                            onChange={(e) => {
                              handleImageUpload(
                                e as ChangeEvent<HTMLInputElement>
                              );
                            }}
                          />
                          <Button component="span" className="upload_image_btn">
                            <AddIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12} />
                  {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Typography className="custom_label">Image</Typography>
                    {image ? (
                      <div className="upload_image_preview2">
                        <CardMedia component="img" image={image} alt="photo" />

                        <CancelIcon
                          onClick={() => {
                            setImage("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image_bnr">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>
                            ) => {
                              const files = (event.target as HTMLInputElement)
                                .files;
                              if (files && files[0].type.includes("image")) {
                                setImage(URL.createObjectURL(files[0]));
                              }
                            }}
                            //   onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn">
                            {/* <img
                                src={
                                  image
                                    ? image
                                    : "/static/images/user_placeholder.png"
                                }
                                alt=""
                              /> */}
                  {/* <AddIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid> */}

                  {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Typography className="custom_label">
                      Upload Your Videos
                    </Typography>
                    {video ? (
                      <div className="upload_image_preview2">
                        {/* <CardMedia component="img" image={video} alt="photo" /> */}
                  {/* <video
                          autoPlay
                          muted
                          loop
                          playsInline
                          className="video_dv"
                          preload="yes"
                        >
                          <source src={video} type="video/mp4" />
                        </video>
                        <CancelIcon
                          onClick={() => {
                            setVideo("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image_bnr">
                        <label htmlFor="icon-button-file-video">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file-video"
                            type="file"
                            inputProps={{
                              accept: "video/*",
                            }}
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>
                            ) => {
                              const files = (event.target as HTMLInputElement)
                                .files;
                              if (files && files[0].type.includes("video/")) {
                                setVideo(URL.createObjectURL(files[0]));
                              }
                            }} */}
                  {/* //   onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn"> */}
                  {/* <img
                                src={
                                  image
                                    ? image
                                    : "/static/images/user_placeholder.png"
                                }
                                alt=""
                              /> */}
                  {/* <AddIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid> */}

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translations.manage_challenge.title || ""}
                      <span className="asterisk">*</span>
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      value={formik.values.title}
                      placeholder={translations.manage_challenge.title || ""}
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      helperText={
                        <h6 className="err_msg">
                          {formik.touched.title && formik.errors.title}
                        </h6>
                      }
                    ></TextField>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translations.manage_challenge.location || ""}
                    </Typography>
                    {/* <TextField
                      hiddenLabel
                      type={"text"}
                      name="message"
                      variant="outlined"
                      fullWidth
                      placeholder="Location"
                    /> */}
                    <Autocomplete
                      onLoad={onLoad}
                      onPlaceChanged={() => onPlaceChanged()}
                    >
                      <TextField
                        className="text_field"
                        hiddenLabel
                        type={"text"}
                        fullWidth
                        placeholder={translations.manage_challenge.location || ""}
                        name="location"
                        variant="outlined"
                        id="location"
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else {
                            formik.handleChange(val);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.location}
                        helperText={
                          formik.touched.location && formik.errors.location
                        }
                        // onKeyPress={onKeypress}
                      />
                    </Autocomplete>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translations.manage_challenge.allowed_entries || ""}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="entries"
                      variant="outlined"
                      value={formik.values.entries}
                      fullWidth
                      placeholder={translations.Globals.no_allowed}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translations.manage_challenge.select_category || ""}
                    </Typography>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        className="select_div"
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={category}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handleCategoryChange}
                      >
                        <MenuItem value="" disabled>
                        {translations.Globals.select}
                        </MenuItem>

                        {categories?.map((item: any, index: any) => (
                          <MenuItem value={item?.name}>{item?.name}</MenuItem>
                        ))}

                        {/* <MenuItem value="Food">Food</MenuItem> */}
                        {/* <MenuItem value="Test">Test</MenuItem> */}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translations.manage_challenge.start_date || ""}
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      name="startDate"
                      placeholder="Start Date"
                      fullWidth
                      value={formik.values.startDate}
                      // name="dateOfTransfer"
                      variant="outlined"
                      type={"datetime-local"}
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                      inputProps={{
                        min: new Date().toISOString().split("T")[0],
                        max: "3000-12-31",
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translations.manage_challenge.end_date || ""}
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      placeholder="End Date"
                      fullWidth
                      name="endDate"
                      value={formik.values.endDate}
                      variant="outlined"
                      type={"datetime-local"}
                      inputProps={{
                        min: formik.values.startDate,
                        max: "3000-12-31",
                      }}
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                    />
                  </Grid>

                  {/* <Grid item lg={6} md={6} sm={6} xs={12} /> */}

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translations.manage_challenge.desc || ""}
                      <span className="asterisk">*</span>
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="description"
                      variant="outlined"
                      className="text_field"
                      value={formik.values.description}
                      fullWidth
                      placeholder= {translations.manage_challenge.desc || ""}
                      multiline
                      minRows={4}
                      maxRows={4}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      helperText={
                        <h6 className="err_msg">
                          {formik.touched.description &&
                            formik.errors.description}
                        </h6>
                      }
                    />
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12} />

                  {challenge?.challengeList?.length ? (
                    <>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Typography className="custom_label">
                          {translations.manage_challenge.list || ""}
                        </Typography>

                        {list?.map((item: any, indexName: any) => (
                          <Grid
                            sx={{ marginBottom: "8px" }}
                            key={indexName}
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                          >
                            <TextField
                              type={"text"}
                              value={list[indexName]?.name}
                              placeholder="Challenge Name"
                              fullWidth
                              onChange={(e) => {
                                let value = e.target.value;
                                let newList = [...list];
                                newList[indexName] = {
                                  ...newList[indexName],
                                  name: value,
                                };
                                setList([...newList]);
                              }}
                            />
                          </Grid>
                        ))}
                      </Grid>

                      {/* // <p>{item?.name}</p> */}
                    </>
                  ) : null}
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    {translations.Globals.submit || ""}
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddChallenge;
