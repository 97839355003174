import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useLazyDeleteRewardsQuery,
  useLazyGetRewardsQuery,
  useLazyRewardsStatusChangeQuery,
} from "../../services/advertiser";
import {
  Loader,
  STORAGE_KEYS,
  getFromStorage,
  showError,
} from "../../constants";
import { isValidInput } from "../../utils/validations";
import { handleDelete, updateStatus } from "../../utils/commonFunctions";
import WarnModal from "../../components/WarnModal";
import { Pagination } from "../../components";
import {
  useLazyChangeGiftStatusQuery,
  useLazyDeleteGiftByIdQuery,
  useLazyGetGiftsQuery,
} from "../../services/gifts";
import { GiftsResponse } from "../../types/General";
import useTranslation from "../../hooks/Translation";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";

const ManageGifts = () => {
  const navigate = useNavigate();
  const userData = useAuth();
  const isArb = getFromStorage(STORAGE_KEYS.language);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [selectId, setSelectedId] = useState<string>("");

  const [gifts, setGifts] = useState<GiftsResponse[]>([]);

  const [getGifts, { isLoading }] = useLazyGetGiftsQuery();
  const [deleteById] = useLazyDeleteGiftByIdQuery();
  const [statusChange] = useLazyChangeGiftStatusQuery();
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const fetchGifts = async () => {
    try {
      const response: any = await getGifts({
        limit: 10,
        page: page,
        search: debouncedSearchTerm.trim(),
      });

      if (response?.data?.statusCode === 200) {
        setGifts(response?.data?.data?.Gifts || []);
        setTotalCount(response?.data?.data?.count);
      } else {
        setGifts([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  let totalPages = Math.ceil(totalCount / 10);
  const checkPermission = () => {
    const permission = userData?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Gifts"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    fetchGifts();
  }, [debouncedSearchTerm, page]);
  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);
  const translations = useTranslation() as any;
  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">
            {translations.manage_gifts.manage_gifts || ""}
          </h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="cards_header">
              <SearchBar
                searchTerm={searchTerm}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                value={searchTerm}
                onCross={() => setSearchTerm("")}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
              <Box className="cards_header_right">
                {hidePermission?.isEdit || userData?.role === "ADMIN" ? (
                  <Button
                    className="btn btn_primary"
                    onClick={() => navigate("/manage-gifts/add")}
                  >
                    {translations.manage_gifts.add || ""}
                  </Button>
                ) : null}
              </Box>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      {translations.table_heads.sno || ""}
                    </TableCell>
                    <TableCell>
                      {translations.table_heads.gift_image || ""}
                    </TableCell>
                    <TableCell>
                      {translations.table_heads.gift_title || ""}
                    </TableCell>
                    <TableCell>
                      {translations.table_heads.gift_worth || ""}
                    </TableCell>
                    <TableCell>
                      {translations.table_heads.status || ""}
                    </TableCell>
                    <TableCell>
                      {translations.table_heads.actions || ""}
                    </TableCell>
                  </TableRow>
                </TableHead>

                {gifts?.length ? (
                  gifts?.map((item, index) => (
                    <TableBody key={item?._id}>
                      <TableRow>
                        <TableCell>{(page - 1) * 10 + index + 1}</TableCell>

                        <TableCell>
                          <figure className="bnr_img">
                            <img
                              src={item?.image || "/static/images/post.png"}
                              alt=""
                            />
                          </figure>
                        </TableCell>
                        <TableCell>
                          {isArb === "ar" ? item?.title_ar : item?.title || "-"}
                        </TableCell>
                        <TableCell>{item?.cost || 0}</TableCell>

                        <TableCell>
                          <Switch
                            onChange={() => {
                              updateStatus(
                                item?._id,
                                !item?.isBlocked,
                                statusChange,
                                setGifts,
                                gifts
                              );
                            }}
                            {...label}
                            checked={!item?.isBlocked}
                            size="small"
                          />
                        </TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            {hidePermission?.isEdit ||
                            userData?.role === "ADMIN" ? (
                              <IconButton
                                onClick={() =>
                                  navigate("/manage-gifts/edit/" + item?._id)
                                }
                              >
                                <ModeEditIcon />
                              </IconButton>
                            ) : null}

                            {hidePermission?.isDelete ||
                            userData?.role === "ADMIN" ? (
                              <IconButton
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(item?._id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))
                ) : (
                  <TableBody>
                    <TableCell
                      align="center"
                      colSpan={10}
                      sx={{ color: "#051140" }}
                    >
                      {translations.manage_gifts.not_found || ""}
                    </TableCell>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Box>
        </Card>
      </div>
      <Pagination
        module={gifts}
        onPageChange={onPageChange}
        page={page}
        totalPages={totalPages}
      />
      <WarnModal
        setOpen={setOpen}
        open={open}
        name={translations.Globals.gift}
        handleDelete={() => {
          handleDelete(deleteById, selectId, fetchGifts);
        }}
      />
    </MainContainer>
  );
};

export default ManageGifts;
