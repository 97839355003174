import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import { useNavigate, useParams } from "react-router-dom";
import {
  useEditSubCategoryByIdMutation,
  useLazyChangeCategoryStatusQuery,
  useLazyDeleteCategoryByIdQuery,
  useLazyGetAllSubCategoriesQuery,
  useLazyGetSubCategoryByIdQuery,
  usePostSubCategoryMutation,
} from "../../services/categories";
import { CategoryResponse } from "../../types/General";
import { showError, showToast } from "../../constants";
import WarnModal from "../../components/WarnModal";
import { handleDelete, updateStatus } from "../../utils/commonFunctions";
import { FamilyRestroom } from "@mui/icons-material";

const SubCategories = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [getSubCategories] = useLazyGetAllSubCategoriesQuery();
  const [deleteCategory] = useLazyDeleteCategoryByIdQuery();
  const [editSubCategory] = useEditSubCategoryByIdMutation();
  const [subCategoryById] = useLazyGetSubCategoryByIdQuery();
  const [changeStatus] = useLazyChangeCategoryStatusQuery();
  const [addSubCategory] = usePostSubCategoryMutation();
  const [category, setCategory] = useState<CategoryResponse[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [name, setName] = useState("");
  const [nameAr, setNameAr] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState("");
  const [open1, setOpen1] = useState(false);
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const getSubCategoryDetail = async (id: string) => {
    try {
      const response = await subCategoryById({
        id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setName(response?.data?.name);
        setNameAr(response?.data?.name_ar);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const handleAddSubCategory = async () => {
    setError(true);
    setLoading(true);
    const body = {
      name: name,
      name_ar: "nameAr",
      appKey: new Date().toISOString(),
      parent: id,
      image: "ff",
    };
    // console.log(body);

    if (!name) {
      setError(true);
      setLoading(false);
    } else {
      if (selectedSubcategory) {
        try {
          const response = await editSubCategory({
            id: selectedSubcategory,
            body,
          }).unwrap();
          if (response?.statusCode === 200) {
            setLoading(false);
            setError(false);
            setOpen(false);
            showToast("Category updated successfully!");
            navigate("/manage-categories", { replace: true });
            setName("");
            setNameAr("");
            getSubCategory();
          }
        } catch (error: any) {
          setLoading(false);
          showError(error?.data?.message || "");
        }
      } else {
        try {
          const response = await addSubCategory(body).unwrap();
          if (response?.statusCode === 200) {
            setLoading(false);
            setError(false);
            setOpen(false);
            showToast("Category created successfully!");
            navigate("/manage-categories", { replace: true });
            setName("");
            setNameAr("");
            getSubCategory();
          }
        } catch (error: any) {
          setLoading(false);
          showError(error?.data?.message || "");
        }
      }
    }
  };

  const getSubCategory = async () => {
    try {
      const response = await getSubCategories({
        query: "",
        page: 1,
        limit: 10,
        categoryId: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setCategory(response?.data?.categories || []);
        setTotalCount(response?.data?.count);
      } else {
        setCategory([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleClose = () => {
    setOpen(false);
    // setSelectedId("");
    // formik.resetForm();
    setName("");
    setNameAr("");
  };

  useEffect(() => {
    getSubCategory();
  }, []);

  useEffect(() => {
    if (selectedSubcategory) {
      getSubCategoryDetail(selectedSubcategory);
    }
  }, [selectedSubcategory]);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>View Sub-Categories</h1>
        </div>
        <Card className="cards">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h4 style={{ margin: 0 }}>Travel</h4>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                sx={{ marginRight: "10px" }}
                className="btn btn_primary"
                onClick={() => setOpen(true)}
              >
                Add Sub-category
              </Button>
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-categories")}
              >
                Back
              </Button>
            </Box>
          </Box>

          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  {/* <TableCell align="center">Image</TableCell> */}
                  <TableCell>Sub-Category Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {category?.map((row, id) => (
                  <TableRow>
                    <TableCell align="center">{1}</TableCell>
                    {/* <TableCell>
                      {" "}
                      <figure className="user_img">
                        <img src="/static/images/user_placeholder.png" alt="" />
                      </figure>
                    </TableCell> */}
                    <TableCell>{row?.name}</TableCell>

                    <TableCell>
                      <Switch
                        {...label}
                        defaultChecked
                        size="small"
                        onChange={() =>
                          updateStatus(
                            row?._id,
                            !row?.isBlocked,
                            changeStatus,
                            setCategory,
                            category
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Box className="table_actions">
                        <IconButton
                          onClick={() => {
                            setOpen(true);
                            setSelectedSubcategory(row?._id);
                          }}
                        >
                          <ModeEditIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setOpen1(true);
                            setSelectedId(row?._id);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
      <Dialog open={open} onClose={handleClose} className="revert_dialog">
        <DialogContent>
          <Typography className="dialog_title">
            {selectedSubcategory ? "Edit Sub-Category" : "Add Sub-Category"}
          </Typography>
          <form>
            <FormControl fullWidth>
              <TextField
                hiddenLabel
                className="text_field"
                autoFocus
                id="category"
                name="category"
                type="text"
                placeholder="Add Sub-Category"
                fullWidth
                value={name}
                onChange={(val) => {
                  setName(val.target.value);
                }}
              />
            </FormControl>
            <Box className="form_btn">
              <Button className="btn btn_primary" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                className="btn btn_primary"
                onClick={handleAddSubCategory}
              >
                Add
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
      <WarnModal
        setOpen={setOpen1}
        open={open1}
        name="Sub-Category"
        handleDelete={() =>
          handleDelete(deleteCategory, selectedId, getSubCategory)
        }
      />
    </MainContainer>
  );
};

export default SubCategories;
