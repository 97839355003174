import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useEditWordByIdMutation,
  useLazyChangeWordStatusQuery,
  useLazyDeleteWordByIdQuery,
  useLazyGetAllWordsQuery,
  useLazyGetWordsByIdQuery,
  usePostWordMutation,
} from "../../services/word";
import { Loader, showError, showToast } from "../../constants";
import { WordResponse } from "../../types/General";
import { handleDelete, updateStatus } from "../../utils/commonFunctions";
import { isString, isValidInput } from "../../utils/validations";
import { Pagination } from "../../components";
import WarnModal from "../../components/WarnModal";
import useTranslation from "../../hooks/Translation";
import { Permissions } from "../../types/User";
import useAuth from "../../hooks/useAuth";

const ManageWords = () => {
  const navigate = useNavigate();
  const userData=useAuth()
  const [addWord] = usePostWordMutation();
  const [editWord] = useEditWordByIdMutation();
  const [getAllWordsQuery] = useLazyGetAllWordsQuery();
  const [getWordsDetails] = useLazyGetWordsByIdQuery();
  const [upateWordStatus] = useLazyChangeWordStatusQuery();
  const [deleteWords] = useLazyDeleteWordByIdQuery();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [name, setName] = useState("");
  const [wordId, setWordId] = useState("");
  const [error, setError] = useState(false);
  const [nameAr, setNameAr] = useState("");
  const [loading, setLoading] = useState(false);
  const [add,setAdd]=useState<boolean>(false)
  const [words, setWords] = useState<WordResponse[]>([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCOunt] = useState(0);
  const [selectedId, setSelectedId] = useState("");
  const [hidePermission, setHidePermission] = useState<
  Permissions | null | undefined
>(null);
  let totalPages = Math.ceil(totalCount / 10);
  const translations=useTranslation()as any
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getWordsList = async () => {
    try {
      const response = await getAllWordsQuery({
        page: page,
        limit: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setWords(response?.data?.words || []);
        setTotalCOunt(response?.data?.count);
      } else {
        setWords([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  const checkPermission = () => {
    const permission = userData?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Words",
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };
  const getWordsDetail = async (id: string) => {
    try {
      const response = await getWordsDetails({
        id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setName(response?.data?.name);
        setNameAr(response?.data?.name_ar);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const handleAddCredit = async () => {
    setError(true);
    setLoading(true);
    // setAdd(false)
    const body = {
      name: name,
      name_ar: nameAr,
      appKey: new Date().toISOString(),
    };
    // console.log(body);

    if (!name || !nameAr) {
      setError(true);
      setLoading(false);
    } else {
      if (wordId) {
        try {
          const response = await editWord({ id: wordId, body }).unwrap();
          if (response?.statusCode === 200) {
            setLoading(false);
            setError(false);
            setOpen(false);
            setWordId("")
            showToast(translations.Globals.word_update);
            navigate("/manage-words", { replace: true });
            setName("");
            setNameAr("");
            getWordsList();
          }
        } catch (error: any) {
          setLoading(false);
          showError(error?.data?.message || "");
        }
      } else {
        try {
          const response = await addWord(body).unwrap();
          if (response?.statusCode === 200) {
            setLoading(false);
            setError(false);
            setOpen(false);
            showToast(translations.Globals.word_create);
            navigate("/manage-words", { replace: true });
            setName("");
            setNameAr("");
            getWordsList();
          }
        } catch (error: any) {
          setLoading(false);
          showError(error?.data?.message || "");
        }
      }
    }
  };

  function createData(Name: string, Email: string, Phone: string) {
    return { Name, Email, Phone };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [createData("Sub Admin", "subadmin@gmail.com", "+971056734321")];

  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setAdd(false)
  };

  const handleEdit = (id: string) => {
    setOpen(true);
    setWordId(id);
    getWordsDetail(id);
  };

  const handleClose = () => {
    setOpen(false);
    setName("");
    setNameAr("");
    // setError(false);
    // setAdd(false)
  };

  useEffect(() => {
    getWordsList();
  }, [page, debouncedSearchTerm]);

  useEffect(() => {
    if (wordId) {
      getWordsDetail(wordId);
    }
  }, [wordId]);
  
  useEffect(()=>{
    if(userData && userData?.permission?.length){
      checkPermission()
    }
  },[userData])

  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translations.manage_word.manage_word||""}</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              {hidePermission?.isEdit || userData?.role==="ADMIN"?(

              <Button className="btn btn_primary" onClick={handleClickOpen}>
              {translations.manage_word.add||""}
              </Button>
              ):null}
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">{translations.table_heads.sno||""}</TableCell>
                  <TableCell>{translations.table_heads.word||""}</TableCell>
                  <TableCell>{translations.table_heads.status||""}</TableCell>
                  <TableCell>{translations.table_heads.actions||""}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {words?.length > 0
                  ? words?.map((row, i) => (
                      <TableRow>
                        <TableCell align="center">{i + 1}</TableCell>
                        <TableCell>{row?.name}</TableCell>
                        <TableCell>
                          <Switch
                            {...label}
                            defaultChecked
                            size="small"
                            checked={!row?.isBlocked}
                            onChange={() =>
                              updateStatus(
                                row?._id,
                                !row?.isBlocked,
                                upateWordStatus,
                                setWords,
                                words
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Box className="table_actions">
                          {hidePermission?.isEdit || userData?.role==="ADMIN"?(
                            
                            <IconButton onClick={()=>{
                             handleEdit(row?._id)
                             setAdd(true)
                            }
                            }>
                              <ModeEditIcon />
                            </IconButton>
                          ):null}
                          {hidePermission?.isDelete || userData?.role ==="ADMIN"?(

                            <IconButton
                              onClick={() => {
                                setOpen1(true);
                                setSelectedId(row?._id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          ):null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  : ""}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          module={words}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>
      <Dialog open={open} onClose={handleClose} className="revert_dialog">
        <DialogContent>
          <Typography className="dialog_title">{add?translations.manage_word.edit||"":translations.manage_word.add||""}</Typography>

          <Typography className="custom_label">{translations.manage_word.name_eng||""}</Typography>
          <FormControl fullWidth>
            <TextField
              hiddenLabel
              autoFocus
              id="name"
              type="text"
              placeholder={translations.manage_word.name_eng||""}
              inputProps={{ maxLength: 30 }}
              fullWidth
              value={name}
              onChange={(val) => {
                if (val.target.value === " " || val.target.value === ".") {
                } else if (isString(val.target.value)) {
                  setName(val.target.value);
                }
              }}
              // onChange={(val) => {
              //   setName(val.target.value);
              // }}
            />
          </FormControl>
          {error && !name ? (
            <h6 className="err_msg modal_err">{translations.Globals.field_req}</h6>
          ) : (
            ""
          )}
          <Typography className="custom_label" sx={{ pt: 2 }}>
          {translations.manage_word.name_arb||""}
          </Typography>
          <FormControl fullWidth>
            <TextField
              hiddenLabel
              autoFocus
              id="name"
              type="text"
              placeholder={translations.manage_word.name_arb||""}
              inputProps={{ maxLength: 30 }}
              fullWidth
              value={nameAr}
              // onChange={(val) => {
              //   setNameAr(val.target.value);
              // }}
              onChange={(val) => {
                if (val.target.value === " " || val.target.value === ".") {
                } else {
                  setNameAr(val.target.value);
                }
              }}
            />
          </FormControl>
          {error && !nameAr ? (
            <h6 className="err_msg modal_err">{translations.Globals.field_req}</h6>
          ) : (
            ""
          )}
          <Box className="form_btn">
            <Button className="btn btn_primary" onClick={handleClose}>
            {translations.Globals.cancel}
            </Button>
            <Button className="btn btn_primary" onClick={handleAddCredit}>
            {translations.Globals.save}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <WarnModal
        setOpen={setOpen1}
        open={open1}
        name={translations.Globals.word}
        handleDelete={() => handleDelete(deleteWords, selectedId, getWordsList)}
      />
    </MainContainer>
  );
};

export default ManageWords;
