import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton
} from "@mui/material";
import React,{useEffect, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyUserChallengeQuery } from "../../services/advertiser";
import { showError } from "../../constants";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useTranslation from "../../hooks/Translation";

const Challenges = () => {
  const {id}=useParams()
  const navigate=useNavigate()
  const [challenge,setChallenge]=useState<any>([])
  const [page,setPage]=useState<number>(1)
  const [totalCount,setTotalCount]=useState<number>(0)
  const [getChallenge,{isLoading}]=useLazyUserChallengeQuery()


  const fetchChallenge=async(id:string|undefined)=>{
    try{
      const response:any=await getChallenge({
        id:id,
        page:page,
        limit:10
      }).unwrap()
      console.log(response?.data?.data?.Challenges)
    
      if(response?.statusCode===200){
        console.log("fff")
        setChallenge(response?.data?.Challenges)
        setTotalCount(response?.data?.count)

      }
    }catch(error:any){
      showError(error?.data?.message||"")
    }
  }

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  useEffect(()=>{
    if(id){
      fetchChallenge(id)
    }
  },[page])
  const translation=useTranslation()as any
let totalPages=Math.ceil(totalCount/10)
  console.log(challenge,"ccc")
  return (
    <div>

  
    <Card sx={{ mt: 4 }} className="cards">
      <Box className="custom_tabs">
        <h2 className="mn_hdng">{translation.manage_users_details.active_comp_challenge||""}</h2>
      </Box>
      <TableContainer className="table_container">
        <Box className="heading"></Box>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">{translation.table_heads.sno||""}</TableCell>
              <TableCell align="center">{translation.table_heads.img_vid||""}</TableCell>
              <TableCell>{translation.table_heads.challenge_title||""}</TableCell>
              {/* <TableCell>Description</TableCell> */}
              <TableCell>{translation.table_heads.location||""}</TableCell>
              {/* <TableCell>Challenge List</TableCell> */}
              {/* <TableCell>Category</TableCell> */}
              <TableCell>{translation.table_heads.status||""}</TableCell>
              <TableCell>{translation.table_heads.actions||""}</TableCell>

              {/* <TableCell>Target Date</TableCell> */}
            </TableRow>
          </TableHead>


          {challenge?.length? (
              challenge?.map((item:any,index:any)=>(
                <TableBody key={index}>
                  <TableRow>
                    <TableCell>
                    {(page - 1) * 10 + index + 1}
                    </TableCell>
                    <TableCell>
                    {item?.uploads[0]?.type==="VIDEO"?(
                 
                 <video
                 autoPlay
                 muted
                 loop
                 playsInline
                 className="hm_vd"
                 preload="yes"
                      
                 key={item?._id}
               >
                 <source  src={
                       item?.uploads[0]?.thumbnail ||
                       "/static/images/post.png"
                     }
                      type="video/mp4" />
               </video>
           ):(
             <figure className="bnr_img">
               <img
                 src={
                   item?.uploads[0]?.link ||
                   "/static/images/post.png"
                 }
                 alt=""
               />
             </figure>
           )}
                    </TableCell>
                    <TableCell>
                      {item?.title || "-"}
                    </TableCell>
                    <TableCell>
                      {item?.city || "-"}
                    </TableCell>
                    {/* <TableCell>
                      {item?.challengeList?.length?(
                        item?.challengeList?.map((items:any,index:any)=>(
                          items.name
                        ))
                      ):(
                        "-"
                      )}
                    </TableCell> */}
                    <TableCell>
                      {item?.isCompleted===false?"Incomplete":"Complete" || "-"}
                    </TableCell>
                    <TableCell>
                    <Box className="table_actions">
                        <IconButton
                          onClick={() =>
                            navigate(
                              `/manage-challenges/details/${item?.challengeId}`,
                              {
                                state: { page: "User" },
                              },
                            )
                          }
                        >
                          <VisibilityIcon />
                        </IconButton>
                        </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))
          ):(

            <TableBody>
            <TableCell
              align="center"
              colSpan={10}
              sx={{ color: "#051140" }}
            >
              No Challenges Found
            </TableCell>
          </TableBody>
          )}



         
        </Table>
      </TableContainer>
    </Card>
    </div>
  );
};

export default Challenges;
