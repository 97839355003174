import { END_POINTS } from "../constants/url";
import {
  AdvertiserResponse,
  CategoryResponse,
  WordResponse,
} from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type GetTokenParams = {
  limit?: number;
  page?: number;
  query?: string;
};

type GetSubCategoryParams = {
  limit?: number;
  page?: number;
  query?: string;
  categoryId: string | undefined;
};

type GetAllAdvertisersResponse = {
  categories: CategoryResponse[];
  count: number;
};

type GetSubCategoryResponse = {
  categories: CategoryResponse[];
  count: number;
};

type AddCategoryBody = {
  name: string;
  image: string;
  name_ar: string;
};

type AddSubCategoryBody = {
  name: string;
  name_ar: string;
  parent: string | undefined;
};

export const categoryApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postCategory: builder.mutation<CommonResponseType, AddCategoryBody>({
      query: (body) => ({
        url: `${END_POINTS.category}`,
        method: "POST",
        body,
      }),
    }),

    postSubCategory: builder.mutation<CommonResponseType, AddSubCategoryBody>({
      query: (body) => ({
        url: `${END_POINTS.category}`,
        method: "POST",
        body,
      }),
    }),

    getAllCategories: builder.query<
      CommonResponseType & { data: GetAllAdvertisersResponse },
      GetTokenParams
    >({
      query: ({ query, page, limit }) => ({
        url: `${END_POINTS.category}?page=${page}&limit=${limit}&search=${query}`,
        method: "GET",
      }),
    }),

    getAllSubCategories: builder.query<
      CommonResponseType & { data: GetSubCategoryResponse },
      GetSubCategoryParams
    >({
      query: ({ page, limit, query, categoryId }) => ({
        url: `${END_POINTS.subCategory}/${categoryId}?page=${page}&limit=${limit}&search=${query}`,
        method: "GET",
      }),
    }),
    getCategoryDetails: builder.query<
      CommonResponseType & { data: CategoryResponse },
      { id: string | undefined }
    >({
      query: ({ id }) => {
        console.log(id, "chececkkc");

        return {
          url: `${END_POINTS.category}/${id}`,
          method: "GET",
        };
      },
    }),
    getSubCategoryById: builder.query<
      CommonResponseType & { data: CategoryResponse },
      { id: string | undefined }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.category}/${id}`,
        method: "GET",
      }),
    }),
    changeCategoryStatus: builder.query<
      CommonResponseType & { data: AdvertiserResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.categoryStatusChange}/${id}`,
        method: "GET",
      }),
    }),

    editCategoryById: builder.mutation<
      CommonResponseType & { data: CategoryResponse },
      { id: string; body: AddCategoryBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.category}/${id}`,
        method: "PUT",
        body,
      }),
    }),

    editSubCategoryById: builder.mutation<
      CommonResponseType & { data: CategoryResponse },
      { id: string; body: AddSubCategoryBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.category}/${id}`,
        method: "PUT",
        body,
      }),
    }),

    deleteCategoryById: builder.query<
      CommonResponseType & { data: CategoryResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.category}/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  usePostCategoryMutation,
  useLazyGetAllCategoriesQuery,
  useLazyGetCategoryDetailsQuery,
  useLazyChangeCategoryStatusQuery,
  useLazyDeleteCategoryByIdQuery,
  useEditCategoryByIdMutation,
  useLazyGetAllSubCategoriesQuery,
  usePostSubCategoryMutation,
  useEditSubCategoryByIdMutation,
  useLazyGetSubCategoryByIdQuery,
} = categoryApi;
