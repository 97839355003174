import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import * as Yup from "yup";
import { useFormik } from "formik";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useTranslation from "../../hooks/Translation";
import {
  useLazyGetPlansByIdQuery,
  useLazyGetPlansQuery,
  useSubscriptionPlanMutation,
  useUpdatePlanMutation,
} from "../../services/advertiser";
import { isNumber, isNumberPrice, isString } from "../../utils/validations";

import { showError, showToast } from "../../constants";
import { Subscription } from "../../types/General";
import { max } from "moment";
import { isDisabled } from "@testing-library/user-event/dist/utils";

const AddSubscriptionPlan = () => {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const [plans, setPlans] = useState<Subscription[]>([]);
  const [plansAdd, setPlansAdd] = useState<Subscription[]>([]);
  const [planType, setPlanType] = useState("");
  const { id } = useParams();
  const [subscriptionPlan] = useSubscriptionPlanMutation();
  const [getPlansById] = useLazyGetPlansByIdQuery();
  const [features, setFeatures] = useState<any>([]);
  const [updatePlan] = useUpdatePlanMutation();
  const [getPlans, { isLoading }] = useLazyGetPlansQuery();
  const translations = useTranslation() as any;
  const isDisabled = (value: string): boolean => {
    return plansAdd?.map((item) => item.subscriptionType).includes(value);
  };

  const fetchPlansAdd = async () => {
    try {
      const response: any = await getPlans({
        // search:debouncedSearchTerm.trim(),
        limit: 10,
        page: 1,
      });
      if (response?.data?.statusCode === 200) {
        setPlansAdd(response?.data?.data?.plans);
        // setTotalCount(response?.data?.data?.count)
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  const [planFeatures, setPlanFeatures] = useState<any>([
    {
      id: 1,
      name: "No ads",
      isBlocked: true,
    },
    {
      id: 2,
      name: "Create Unlimited challenges",
      isBlocked: true,
    },
    {
      id: 3,
      name: "Create unlimited journeys",
      isBlocked: true,
    },
    {
      id: 4,
      name: "Earn 1000 points",
      isBlocked: true,
    },
  ]);

  const handlePlanType = (event: SelectChangeEvent) => {
    setPlanType(event.target.value as string);
  };

  const fetchPlans = async (id: string) => {
    try {
      const response: any = await getPlansById({
        id: id,
      });
      if (response?.data?.statusCode === 200) {
        setPlans(response?.data?.data);
        console.log(response?.data?.data?.subscriptionType);
        setPlanType(response?.data?.data[0]?.subscriptionType);
        const data = response?.data?.data[0]?.features;
        console.log(data, "data");
        const updatedPlanFeatures = planFeatures.map((value: any) => {
          const incomingFeature = data.find(
            (item: any) => item.name === value.name
          );
          if (incomingFeature) {
            return {
              ...value,
              _id: incomingFeature._id,
              isBlocked: incomingFeature.isBlocked,
            };
          } else {
            return value;
          }
        });

        console.log(updatedPlanFeatures, "update");

        setPlanFeatures(updatedPlanFeatures);
        if (state.type === "edit") {
          setFeatures(
            updatedPlanFeatures.map((feature: any) => ({
              _id: feature._id,
              isBlocked: feature.isBlocked,
            }))
          );
        } else {
          // setFeatures(updatedPlanFeatures.map((feature: any) => ({ _id: feature._id, isBlocked: feature.isBlocked,name:feature.name })));
        }
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name_eng: plans[0]?.subscriptionName || "",
      name_arb: plans[0]?.subscriptionName_ar || "",
      // validity:plans[0]?.validity|| "",

      price: plans[0]?.subscriptionPrice || "",
      desc_eng: plans[0]?.description || "",
      desc_arb: plans[0]?.description_ar || "",
    },
    validationSchema: Yup.object({
      name_eng: Yup.string()
        .required(translations.Globals.field_req)
        .min(2, translations.Globals.min_2)
        .max(80, translations.Globals.max_80),
      name_arb: Yup.string()
        .required(translations.Globals.field_req)
        .min(2, translations.Globals.min_2)
        .max(80, translations.Globals.max_80),
      // validity: Yup.number().required("This field is required"),

      price: Yup.number().required(translations.Globals.field_req),
      desc_eng: Yup.string()
        .required(translations.Globals.field_req)
        .min(2, translations.Globals.min_2)
        .max(80, translations.Globals.max_80),
      desc_arb: Yup.string()
        .required(translations.Globals.field_req)
        .min(2, translations.Globals.min_2)
        .max(80, translations.Globals.max_80),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);

      if (planType.length === 0) {
        showError(translations.Globals.plan_type);
        return;
      }

      if (features.length === 0) {
        showError(translations.Globals.feature);
        return;
      }

      const body = {
        subscriptionName: values.name_eng,
        subscriptionName_ar: values.name_arb,
        description: values.desc_eng,
        description_ar: values.desc_arb,
        subscriptionPrice: values.price,
        // validity: values.validity,
        subscriptionType: planType,
        features: features,
        appKey: new Date().toISOString(),
      };
      console.log(body, "bodyyy");
      if (id) {
        try {
          const response: any = await updatePlan({
            body: body,
            id: id,
          });
          if (response?.data?.statusCode === 200) {
            showToast(translations.Globals.subs_update);
            navigate("/manage-subscription");
          }

          console.log(response);
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      } else {
        try {
          const response: any = await subscriptionPlan({
            body: body,
          });
          if (response?.data?.statusCode === 200) {
            showToast(translations.Globals. subs_add);
            navigate("/manage-subscription");
          }

          console.log(response);
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      }
    },
  });

  const handlePlanChange = (Selectname: string, id: number) => {
    let newData = planFeatures.findIndex(
      (data: any) => data.name === Selectname
    );
    console.log(newData, "data");

    const data = [...planFeatures];
    console.log(data[newData]);

    if (data[newData].isBlocked) {
      console.log("tr");
      data[newData] = {
        ...data[newData],
        isBlocked: false,
      };
    } else {
      data[newData] = {
        ...data[newData],
        isBlocked: true,
      };
    }

    setPlanFeatures([...data]);
    console.log(planFeatures, "plan");
    console.log(data, "data");

    const details: any = [];
    if (state?.type === "edit") {
      data.forEach((element: any) => {
        details.push({ _id: element._id, isBlocked: element.isBlocked });
      });
    } else {
      data.forEach((element: any) => {
        details.push({ isBlocked: element.isBlocked, name: element.name });
      });
    }

    // data.forEach((element: any) => {
    //   details.push({ _id: element._id, isBlocked: element.isBlocked });
    // });

    console.log(details);

    setFeatures(details);
  };

  useEffect(() => {
    if (id) {
      fetchPlans(id);
    } else {
      fetchPlansAdd();
    }
  }, []);

  console.log(planFeatures, "features");

  console.log(plans, "plans");
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          {state?.type === "edit" ? (
            <h1 className="mn_hdng">
              {translations.manage_subscriptions.edit || ""}
            </h1>
          ) : (
            <h1 className="mn_hdng">
              {translations.manage_subscriptions.add_plan || ""}
            </h1>
          )}
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-subscription");
            }}
          >
            {translations.Globals.back || ""}
          </Button>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translations.manage_subscriptions.plan_name_eng || ""}
                    <span className="asterisk">*</span>
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="name_eng"
                    variant="outlined"
                    value={formik.values.name_eng}
                    fullWidth
                    placeholder= {translations.manage_subscriptions.plan_name_eng || ""}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    helperText={
                      <h6 className="err_msg">
                        {formik.touched.name_eng && formik.errors.name_eng}
                      </h6>
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translations.manage_subscriptions.plan_name_arb || ""}
                    <span className="asterisk">*</span>
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="name_arb"
                    variant="outlined"
                    value={formik.values.name_arb}
                    fullWidth
                    placeholder= {translations.manage_subscriptions.plan_name_arb || ""}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    helperText={
                      <h6 className="err_msg">
                        {formik.touched.name_arb && formik.errors.name_arb}
                      </h6>
                    }
                  />
                </Grid>
                {/* plan validity */}
                {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translations.manage_subscriptions.plan_validity || ""}
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="validity"
                    variant="outlined"
                    fullWidth
                    value={formik.values.validity}
                    placeholder="Plan Validity"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isNumber(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    helperText={
                      <h6 className="err_msg">
                        {formik.touched.validity && formik.errors.validity}
                      </h6>
                    }
                  />
                </Grid> */}
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translations.manage_subscriptions.plan_type || ""}
                  </Typography>
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      className="select_div"
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={planType}
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      sx={{
                        cursor: state?.type === "edit" ? "not-allowed" : "none",
                      }}
                      displayEmpty
                      onChange={handlePlanType}
                      disabled={state?.type === "edit" ? true : false}
                    >
                      <MenuItem value="" disabled>
                       {translations.Globals.select}
                      </MenuItem>
                      <MenuItem
                        value="MONTHLY"
                        disabled={
                          state?.type !== "edit" ? isDisabled("MONTHLY") : false
                        }
                      >
                        {translations.graph_menu.monthly}
                      </MenuItem>
                      <MenuItem
                        value="HALF_YEARLY"
                        disabled={
                          state?.type !== "edit"
                            ? isDisabled("HALF_YEARLY")
                            : false
                        }
                      >
                        {translations.Globals.Half_Yearly}
                      </MenuItem>
                      <MenuItem
                        value="YEARLY"
                        disabled={
                          state?.type !== "edit" ? isDisabled("YEARLY") : false
                        }
                      >
                         {translations.graph_menu.yearly}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translations.manage_subscriptions.plan_price || ""}
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="price"
                    variant="outlined"
                    fullWidth
                    placeholder=  {translations.manage_subscriptions.plan_price || ""}
                    value={formik.values.price}
                    inputProps={{
                      // inputMode:"numeric",
                      maxLength: 8,
                      // pattern: /^(?!.*\..*\.)[0-9]*(\.[0-9]{0,2})?$/
                    }}
                    onChange={(val) => {
                      if (val.target.value === " ") {
                      } else if (isNumberPrice(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    helperText={
                      <h6 className="err_msg">
                        {formik.touched.price && formik.errors.price}
                      </h6>
                    }
                  />
                </Grid>
                {/* <Grid item lg={6} md={6} sm={6} xs={12} /> */}
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translations.manage_subscriptions.desc_eng || ""}
                    <span className="asterisk">*</span>
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="desc_eng"
                    variant="outlined"
                    fullWidth
                    placeholder={translations.manage_subscriptions.desc_eng || ""}
                    className="text_field"
                    value={formik.values.desc_eng}
                    multiline
                    minRows={4}
                    maxRows={4}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    helperText={
                      <h6 className="err_msg">
                        {formik.touched.desc_eng && formik.errors.desc_eng}
                      </h6>
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translations.manage_subscriptions.desc_arb || ""}{" "}
                    <span className="asterisk">*</span>
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="desc_arb"
                    variant="outlined"
                    className="text_field"
                    fullWidth
                    value={formik.values.desc_arb}
                    placeholder={translations.manage_subscriptions.desc_arb || ""}
                    multiline
                    minRows={4}
                    maxRows={4}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    helperText={
                      <h6 className="err_msg">
                        {formik.touched.desc_arb && formik.errors.desc_arb}
                      </h6>
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      component="h2"
                      sx={{
                        fontSize: "20px",
                        color: "#051140",
                        fontWeight: 700,
                      }}
                    >
                      {translations.manage_subscriptions.plan_features || ""}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <TableContainer
                    className="table_container"
                    sx={{ margin: 0 }}
                  >
                    <Box className="heading"></Box>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">
                            {translations.table_heads.sno || ""}
                          </TableCell>
                          <TableCell>
                            {translations.table_heads.features || ""}
                          </TableCell>
                          <TableCell>
                            {" "}
                            {translations.table_heads.status || ""}
                          </TableCell>
                          {/* <TableCell>Actions</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {planFeatures.map((row: any, i: any) => (
                          <TableRow key={i}>
                            <TableCell align="center">{i + 1}</TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>
                              <Switch
                                {...label}
                                checked={!row?.isBlocked}
                                onChange={() => {
                                  handlePlanChange(row.name, row.id);
                                }}
                                defaultChecked
                                size="small"
                              />
                            </TableCell>
                            {/* <TableCell>
                              <Box className="table_actions">
                                <IconButton>
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </TableCell> */}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
              <div className="form_btn">
                <Button size="large" type="submit" className="btn btn_primary">
                  {translations.Globals.save || ""}
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default AddSubscriptionPlan;
