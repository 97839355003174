import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
} from "@mui/material";

import {
  useLazyChangeUserStatusQuery,
  useLazyDeleteUserByIdQuery,
  useLazyGetUserCSVQuery,
  useLazyGetUsersQuery,
} from "../../services/user";

import MainContainer from "../../layout/MainContainer";
import SearchBar from "../../components/SearchBar";
import { UserResponse } from "../../types/General";
import { Loader, showError } from "../../constants";
import { isValidInput } from "../../utils/validations";
import { Pagination } from "../../components";
import { handleDelete, updateStatus } from "../../utils/commonFunctions";
import WarnModal from "../../components/WarnModal";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";
import useTranslation from "../../hooks/Translation";

const ManageUsers = () => {
  const userData = useAuth();
  const navigate = useNavigate();
const translation=useTranslation()as any
  const [open, setOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [selectedId, setSelectedId] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [users, setUsers] = useState<UserResponse[]>([]);
  const [hidePermission, setHidePermission] = useState<
  Permissions | null | undefined
>(null);

  const [getUsers, { isLoading }] = useLazyGetUsersQuery();
  const [updateUserStatus] = useLazyChangeUserStatusQuery();
  const [deleteById] = useLazyDeleteUserByIdQuery();
  const [getUserCsvMethod] = useLazyGetUserCSVQuery();

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const checkPermission = () => {
    const permission = userData?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Users"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  const getUsersList = async () => {
    try {
      const response = await getUsers({
        page: page,
        limit: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setUsers(response?.data?.user || []);
        setTotalCount(response?.data?.count);
      } else {
        setUsers([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleExportCsv = async () => {
    try {
      const res = await getUserCsvMethod({search:searchTerm}).unwrap();
      if (res?.statusCode === 200) {
        window.open(res?.data || "");
      }
    } catch (error: any) {
      showError(error?.message);
    }
  };

  useEffect(() => {
    getUsersList();
  }, [debouncedSearchTerm, page]);

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);


  

  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translation.manage_users.manage_user ||""}</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              <Button className="btn btn_primary" onClick={handleExportCsv}>
                <FileDownloadIcon /> {translation.Globals.export_csv || ""}
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">{translation.table_heads.sno||""}</TableCell>

                  <TableCell>{translation.table_heads.username||""}</TableCell>
                  <TableCell>{translation.table_heads.phone||""}</TableCell>
                  <TableCell>{translation.table_heads.email||""}</TableCell>
                  <TableCell>{translation.table_heads.account_status||""}</TableCell>
                  <TableCell>{translation.table_heads.actions||""}</TableCell>
                </TableRow>
              </TableHead>
              {users?.length ? (
                users?.map((item, i) => {
                  return (
                    <TableBody key={item?._id}>
                      <TableRow>
                        <TableCell align="center">
                          {" "}
                          {(page - 1) * 10 + i + 1}
                        </TableCell>

                        <TableCell>{item?.userName || "-"}</TableCell>

                        <TableCell>
                          {item?.countryCode
                            ? (item?.countryCode.includes("+") ? "" : "+") +
                              item?.countryCode
                            : null}{" "}
                          {item?.phone || "-"}
                        </TableCell>
                        <TableCell>{item?.email || "-"}</TableCell>

                        <TableCell>
                          <Switch
                            {...label}
                            size="small"
                            checked={!item?.isBlocked}
                            onChange={() =>
                              updateStatus(
                                item?._id,
                                !item?.isBlocked,
                                updateUserStatus,
                                setUsers,
                                users
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            <IconButton
                              onClick={() =>
                                navigate(
                                  `/manageusers/userdetails/${item?._id}`
                                )
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                            {hidePermission?.isEdit ||
                            userData?.role === "ADMIN" ? (
                            <IconButton
                              onClick={() =>
                                navigate(`/manageusers/usersform/${item?._id}`)
                              }
                            >
                              <ModeEditIcon />
                            </IconButton>
                              ) : null}
                               {hidePermission?.isDelete ||
                            userData?.role === "ADMIN" ? (
                            <IconButton>
                              <DeleteIcon
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(item?._id);
                                }}
                              />
                            </IconButton>
                                  ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : (
                <TableBody>
                  <TableCell
                    align="center"
                    colSpan={10}
                    sx={{ color: "#051140" }}
                  >
                    {translation.manage_users.no_user_found ||""}
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          module={users}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
          
        />
      </div>
      <WarnModal
        setOpen={setOpen}
        open={open}
        name={translation.manage_users.user ||""}
        handleDelete={() => handleDelete(deleteById, selectedId, getUsersList)}
      />
    </MainContainer>
  );
};

export default ManageUsers;
