import { Routes, Route } from "react-router-dom";
import Pages from "./pages";
import UserDetails from "./pages/users/details";
import UsersForm from "./pages/users/form/usersForm";

const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<Pages.LoginPage />} />
      <Route path="forgotpassword" element={<Pages.ForgotPasswordPage />} />
      <Route path="verifyotp" element={<Pages.VerifyOtp />} />
      <Route path="resetpassword" element={<Pages.ResetPassword />} />
      <Route path="changepassword" element={<Pages.ChangePassword />} />
      <Route path="/dashboard" element={<Pages.DashBoardPage />} />
      <Route path="/profile" element={<Pages.Profile />} />
      <Route path="/manageusers" element={<Pages.ManageUsers />} />
      <Route path="/manageusers/userdetails/:id" element={<UserDetails />} />
      <Route path="/manageusers/usersform/:id" element={<UsersForm />} />
      <Route path="/analytics" element={<Pages.Analytics />} />
      <Route path="/faq" element={<Pages.Faq />} />
      <Route path="/cms" element={<Pages.ManageCms />} />
      <Route
        path="/manage-advertisers"
        element={<Pages.ManageServiceProvider />}
      />
      <Route
        path="/manage-advertisers/details/:id"
        element={<Pages.ServiceProviderDetails />}
      />
      <Route
        path="/manage-advertisers/add"
        element={<Pages.AddServiceProvider />}
      />
      <Route
        path="/manage-advertisers/edit/:id"
        element={<Pages.AddServiceProvider />}
      />
      <Route
        path="/manage-notifications"
        element={<Pages.ManageNotifications />}
      />
      <Route
        path="/manage-notifications/add"
        element={<Pages.AddNotification />}
      />
      <Route
        path="/recieved-notifications"
        element={<Pages.RecievedNotifications />}
      />
      <Route path="/customer-support" element={<Pages.CustomerSupport />} />
      <Route
        path="/customer-support/details/:id"
        element={<Pages.QueryDetails />}
      />
      <Route path="/manage-subAdmin" element={<Pages.ManageSubAdmin />} />
      <Route path="/manage-subAdmin/add" element={<Pages.AddSubAdmin />} />
      <Route path="/manage-subAdmin/edit/:id" element={<Pages.AddSubAdmin />} />

      <Route path="/manage-categories" element={<Pages.ManageCategories />} />
      <Route path="/manage-categories/add" element={<Pages.AddCategories />} />
      <Route
        path="/manage-categories/edit/:id"
        element={<Pages.AddCategories />}
      />
      <Route
        path="/manage-categories/details/:id"
        element={<Pages.SubCategories />}
      />

      <Route path="/manage-revenue" element={<Pages.ManageRevenue />} />
      <Route path="/manage-words" element={<Pages.ManageWords />} />
      <Route path="/manage-challenges" element={<Pages.ManageChallenges />} />
      <Route path="/manage-challenges/add" element={<Pages.AddChallenge />} />
      <Route
        path="/manage-challenges/edit/:id"
        element={<Pages.AddChallenge />}
      />
      <Route
        path="/manage-challenges/details/:id"
        element={<Pages.ChallengeDetails />}
      />
      <Route
        path="/manage-subscription"
        element={<Pages.ManageSubscription />}
      />
      <Route
        path="/manage-subscription/add"
        element={<Pages.AddSubscriptionPlan />}
      />
      <Route
        path="/manage-subscription/edit/:id"
        element={<Pages.AddSubscriptionPlan />}
      />
      <Route path="/manage-content" element={<Pages.ManageContent />} />
      <Route
        path="/manage-content/details/:id"
        element={<Pages.ContentDetails />}
      />
      <Route path="/manage-rewards" element={<Pages.ManageRewards />} />
      <Route path="/manage-rewards/add" element={<Pages.AddRewards />} />
      <Route path="/manage-rewards/edit/:id" element={<Pages.AddRewards />} />
      <Route
        path="/manage-advertisements"
        element={<Pages.ManageAdvertisements />}
      />
      <Route
        path="/manage-advertisements/details/:id"
        element={<Pages.AdvertisementDetails />}
      />
      <Route path="/story-details/:id" element={<Pages.StoryDetails />} />
      <Route path="/manage-journeys" element={<Pages.ManageJourneys />} />
      <Route
        path="/manage-journeys/details/:id"
        element={<Pages.JourneyDetails />}
      />
      <Route path="/manage-gifts" element={<Pages.ManageGifts />} />
      <Route path="/manage-gifts/add" element={<Pages.AddGifts />} />
      <Route path="/manage-gifts/edit/:id" element={<Pages.AddGifts />} />
    </Routes>
  );
};

export default Routing;
