import { END_POINTS } from "../constants/url";
import { GetSubadminResponse } from "../types/General";
// import { GetSubadminResponse } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};
type GetAllSubadminsResponse = {
  count: number;
  subAdmin: GetSubadminResponse[];
};

type GetSubadminResponseType = {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  countryCode: string;
  image: string;
  role: string;
  permissions: {
    label: string;
    isEdit: boolean;
    isView: boolean;
    isDelete: boolean;
  }[];
};

type PostSubadminBody = {
  email?: string;
  firstName: string;
  lastName: string;
  countryCode?: string;
  phone?: string;
  image: string;
  permission: {
    label?: string | undefined;
    isView?: boolean;
    isEdit?: boolean;
    isDelete?: boolean;
  }[];
};

export const subadminApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllSubAdmins: builder.query<
      CommonResponseType & { data: GetAllSubadminsResponse },
      { page: number; limit: number; query: string }
    >({
      query: ({ page, limit, query }) => ({
        url:
          END_POINTS.subAdmin +
          "?page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    postSubadmin: builder.mutation<
      CommonResponseType & { data: GetSubadminResponseType },
      PostSubadminBody
    >({
      query: (body) => ({
        url: `${END_POINTS.subAdmin}`,
        method: "POST",
        body,
      }),
    }),
    changeSubAdminStatus: builder.query<
      CommonResponseType & { data: GetSubadminResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.subAdminStatusChange}/${id}`,
        method: "GET",
      }),
    }),

    getSubadminById: builder.query<
      CommonResponseType & { data: GetSubadminResponse },
      { sub_id: string }
    >({
      query: ({ sub_id }) => ({
        url: `${END_POINTS.subAdmin}/${sub_id}`,
        method: "GET",
      }),
    }),
    updateSubAdmin: builder.mutation<
      CommonResponseType & { data: GetSubadminResponseType },
      { body: PostSubadminBody; sub_id: string }
    >({
      query: ({ body, sub_id }) => ({
        url: `${END_POINTS.subAdmin}/${sub_id}`,
        method: "PUT",
        body,
      }),
    }),
    deleteSubAdminById: builder.query<
      CommonResponseType & { data: GetAllSubadminsResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.subAdmin}/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyGetAllSubAdminsQuery,
  usePostSubadminMutation,
  useLazyChangeSubAdminStatusQuery,
  useLazyGetSubadminByIdQuery,
  useUpdateSubAdminMutation,
  useLazyDeleteSubAdminByIdQuery,
} = subadminApi;
