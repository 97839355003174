import { END_POINTS } from "../constants/url";
import { FaqRes } from "../types/General";
import emptySplitApi from "../utils/rtk";

type GetCmsResponse = {
  statusCode: number;
  message: string;
  data: {
    support: string;
    support_ar: string;
    privacyPolicy: string;
    legal: string;
    legal_ar: string;
    privacyPolicy_ar: string;
    email: string;
    phone: string;
    countryCode: string;
    isDeleted: boolean;
    _id: string;
    termsAndConditions: string;
    termsAndConditions_ar: string;
    aboutUs: string;
    aboutUs_ar: string;
  }[];
};

type PostCmsBody = {
  email: string;
  phone: string;
  countryCode: string;
  termsAndConditions: string;
  termsAndConditions_ar: string;
  privacyPolicy: string;
  privacyPolicy_ar: string;
  aboutUs: string;
  aboutUs_ar: string;
};
type GetFaqResponse = {
  statusCode: number;
  message: string;
  data: {
    data: FaqRes[];
    count: number;
  };
};
type PostFaqBody = {
  question: string;
  question_ar: string;
  answer: string;
  answer_ar: string;
};

export const cmsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCms: builder.query<GetCmsResponse, {}>({
      query: () => ({
        url: END_POINTS.cms,
        method: "GET",
      }),
    }),

    updateCms: builder.mutation<
      GetCmsResponse,
      { body: PostCmsBody; cms_id: string }
    >({
      query: ({ body, cms_id }) => ({
        url: `${END_POINTS.cms}/${cms_id}`,
        method: "PUT",
        body,
      }),
    }),
    getFaq: builder.query<GetFaqResponse, {}>({
      query: () => ({
        url: `${END_POINTS.faq}`,
        method: "GET",
      }),
    }),
    addFaq: builder.mutation<GetFaqResponse, { body: PostFaqBody }>({
      query: ({ body }) => ({
        url: `${END_POINTS.faq}`,
        method: "POST",
        body,
      }),
    }),
    deleteFaq: builder.query<GetFaqResponse, { faq_id: string }>({
      query: ({ faq_id }) => ({
        url: `${END_POINTS.faq}/${faq_id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyGetCmsQuery,
  useUpdateCmsMutation,
  useLazyGetFaqQuery,
  useAddFaqMutation,
  useLazyDeleteFaqQuery,
} = cmsApi;
