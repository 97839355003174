import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Tabs,
  Tab,
  Typography,
  DialogContent,
  TextField,
  Dialog,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import useTranslation from "../../hooks/Translation";
import {
  useLazyDeletePlanQuery,
  useLazyGetPlansByIdQuery,
  useLazyGetPlansQuery,
  useLazyPlanStatusQuery,
} from "../../services/advertiser";
import { getFromStorage, showError } from "../../constants";
import { Subscription } from "../../types/General";
import WarnModal from "../../components/WarnModal";
import { handleDelete, updateStatus } from "../../utils/commonFunctions";
import { Pagination } from "../../components";
import { isValidInput } from "../../utils/validations";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";

const ManageSubscription = () => {
  const [value, setValue] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [selectId, setSelectID] = useState<string>("");
  const [deleteById] = useLazyDeletePlanQuery();

  const handleClose = () => {
    setOpen(false);
    // formik.setFieldError("message", "");
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const navigate = useNavigate();
  function createData(
    name: string,
    planType: string,
    planPrice: string,
    email: string,
    planName: string,
    postedAds: string,
    earnings: string,
    bankAccounts: string
  ) {
    return {
      name,
      planType,
      planPrice,
      email,
      planName,
      postedAds,
      earnings,
      bankAccounts,
    };
  }
  // const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData(
      "Plan 1",
      "Monthly",
      "$10",
      "abdulhussian67@gmail.com",
      "Starter",
      "1 Month / 600 SAR",
      "600 SAR",
      "2"
    ),
    createData(
      "Plan 2",
      "Monthly",
      "$79",
      "abdulhussian67@gmail.com",
      "Starter",
      "1 Month / 600 SAR",
      "600 SAR",
      "4"
    ),
    createData(
      "Plan 3",
      "Monthly",
      "$120",
      "abdulhussian67@gmail.com",
      "Starter",
      "1 Month / 600 SAR",
      "600 SAR",
      "6"
    ),
  ];

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const planFeatures = [
    {
      id: 1,
      name: "Enhanced Budget Tools",
      enable: true,
    },
    {
      id: 2,
      name: "Link Bank Accounts",
      enable: true,
    },
    {
      id: 3,
      name: "Advanced Security",
      enable: true,
    },
    {
      id: 4,
      name: "Track Spending Habbits",
      enable: true,
    },
    {
      id: 5,
      name: "Graphs and Charts",
      enable: true,
    },
    {
      id: 6,
      name: "Savings jar to visually watch the progress",
      enable: true,
    },
    {
      id: 7,
      name: "Recommendations and Analytics",
      enable: true,
    },
    {
      id: 8,
      name: "Educational Tips",
      enable: true,
    },
    {
      id: 9,
      name: "Savings Goals",
      enable: true,
    },
    {
      id: 10,
      name: "Round-Up Savings to Achieve Goals",
      enable: true,
    },
    // {
    //   id: 11,
    //   name: "With No Ads",
    //   enable: true,
    // },
    // {
    //   id: 12,
    //   name: "Notifications",
    //   enable: true,
    // },
    // {
    //   id: 13,
    //   name: "Reward Points",
    //   enable: true,
    // },
    // {
    //   id: 14,
    //   name: "Bill Reminders",
    //   enable: true,
    // },
    // {
    //   id: 15,
    //   name: "Multiplier to Accelerate Goals",
    //   enable: true,
    // },
    // {
    //   id: 16,
    //   name: "Manual ACH transfers between the external accounts",
    //   enable: true,
    // },
    // {
    //   id: 17,
    //   name: "Auto ACH transfers between the external accounts",
    //   enable: true,
    // },
  ];
  const [getPlans, { isLoading }] = useLazyGetPlansQuery();
  const [plans, setPlans] = useState<Subscription[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [statusChange] = useLazyPlanStatusQuery();
  const isArb = getFromStorage("LANGUAGE");
  const userData = useAuth();
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const fetchPlans = async () => {
    try {
      const response: any = await getPlans({
        search: debouncedSearchTerm.trim(),
        limit: 10,
        page: page,
      });
      if (response?.data?.statusCode === 200) {
        setPlans(response?.data?.data?.plans);
        setTotalCount(response?.data?.data?.count);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  const translations = useTranslation() as any;

  useEffect(() => {
    fetchPlans();
  }, [searchTerm, debouncedSearchTerm, page]);
  console.log(plans);

  const checkPermission = () => {
    const permission = userData?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Subscription Plans"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const totalPages = Math.ceil(totalCount / 10);
  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">
            {translations.manage_subscriptions.manage_subscription || ""}
          </h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="cards_header">
              <SearchBar
                searchTerm={searchTerm}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                value={searchTerm}
                onCross={() => setSearchTerm("")}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
              <Box className="cards_header_right">
                {hidePermission?.isEdit || userData?.role === "ADMIN" ? (
                  <Button
                    className="btn btn_primary"
                    onClick={() => navigate("/manage-subscription/add")}
                  >
                    {translations.manage_subscriptions.add_plan || ""}
                  </Button>
                ) : null}
              </Box>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      {translations.table_heads.sno || ""}
                    </TableCell>
                    <TableCell>
                      {translations.table_heads.plane_name || ""}
                    </TableCell>
                    <TableCell>
                      {translations.table_heads.plan_validity || ""}
                    </TableCell>
                    <TableCell>
                      {translations.table_heads.plan_price || ""}
                    </TableCell>
                    {/* <TableCell>Number of Bank Accounts </TableCell> */}

                    <TableCell>
                      {" "}
                      {translations.table_heads.status || ""}
                    </TableCell>
                    <TableCell>
                      {translations.table_heads.actions || ""}
                    </TableCell>
                  </TableRow>
                </TableHead>

                {plans?.length ? (
                  plans?.map((item, index) => (
                    <TableBody key={item?._id}>
                      <TableRow>
                        <TableCell>{(page - 1) * 10 + index + 1}</TableCell>
                        <TableCell>
                          {isArb === "ar"
                            ? item?.subscriptionName_ar
                            : item?.subscriptionName}
                        </TableCell>
                        <TableCell>
                          {item?.subscriptionType === "MONTHLY"
                            ? "Monthly"
                            : item?.subscriptionType === "BASIC"
                              ? "Basic"
                              : item?.subscriptionType === "HALF_YEARLY"
                                ? "Half Yearly"
                                : "Yearly"}
                        </TableCell>
                        <TableCell>{item?.subscriptionPrice || "0"}</TableCell>
                        <TableCell>
                          <Switch
                            {...label}
                            onChange={() => {
                              item?.subscriptionType === "BASIC"
                                ? showError("Basic Plan cannot be disabled")
                                : updateStatus(
                                    item?._id,
                                    !item?.isBlocked,
                                    statusChange,
                                    setPlans,
                                    plans
                                  );
                            }}
                            checked={!item?.isBlocked}
                            size="small"
                          />
                        </TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            {hidePermission?.isEdit ||
                            userData?.role === "ADMIN" ? (
                              <IconButton
                                // disabled={item?.subscriptionName==="Basic"?true:false}
                                onClick={() =>
                                  item?.subscriptionName === "Basic"
                                    ? showError(
                                        translations.Globals.basic_edit || ""
                                      )
                                    : navigate(
                                        "/manage-subscription/edit/" +
                                          item?._id,
                                        {
                                          state: { type: "edit" },
                                        }
                                      )
                                }
                              >
                                <ModeEditIcon />
                              </IconButton>
                            ) : null}

                            {hidePermission?.isDelete ||
                            userData?.role === "ADMIN" ? (
                              <IconButton
                                //  disabled={item?.subscriptionName==="Basic"?true:false}

                                onClick={() => {
                                  if (item?.subscriptionName === "Basic") {
                                    showError(translations.Globals.basic || "");
                                  } else {
                                    setOpen(true);
                                    setSelectID(item?._id);
                                  }
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))
                ) : (
                  <TableBody>
                    <TableCell
                      align="center"
                      colSpan={10}
                      sx={{ color: "#051140" }}
                    >
                      {translations.Globals.no_subs}
                    </TableCell>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Box>
        </Card>
      </div>
      <Pagination
        totalPages={totalPages}
        page={page}
        module={plans}
        onPageChange={onPageChange}
      />
      <WarnModal
        open={open}
        setOpen={setOpen}
        name={translations.Globals.subs_plan}
        handleDelete={() => {
          handleDelete(deleteById, selectId, fetchPlans);
        }}
      />
    </MainContainer>
  );
};

export default ManageSubscription;
