import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import LanguageIcon from "@mui/icons-material/Language";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import {
  Box,
  Button,
  Checkbox,
  Container,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";

import { setCredentials } from "../../reducers/authSlice";
import { useAppDispatch } from "../../hooks/store";
import { usePostLoginMutation } from "../../services/auth";

import "./Login.scss";

import {
  Loader,
  STORAGE_KEYS,
  getFromStorage,
  removeFromStorage,
  setToStorage,
  showError,
} from "../../constants";
import useTranslation from "../../hooks/Translation";
import { useLanguageContext } from "../../context/LanguageContext";

const Login = () => {
  const translation = useTranslation() as any;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { language, changeLanguage } = useLanguageContext();
  const getLang = getFromStorage(STORAGE_KEYS.language);

  const [anchorElLang, setAnchorElLang] = useState<null | HTMLElement>(null);
  const openLang = Boolean(anchorElLang);

  const [showPassword, setShowPassword] = useState(false);

  const [loginMutation, { isLoading }] = usePostLoginMutation();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    const inputEl: HTMLInputElement | null = document.getElementById(
      "password"
    ) as HTMLInputElement | null;
    if (inputEl) {
      setTimeout(() => {
        inputEl.setSelectionRange(inputEl.value.length, inputEl.value.length);
      }, 0);
    }
  };
  const handleLangChange = (val: string) => {
    setToStorage(STORAGE_KEYS.language, val);
    changeLanguage(val);
    setAnchorElLang(null);
    window.location.reload();
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },
    validationSchema: Yup.object({
      remember: Yup.boolean(),
      email: Yup.string()
        .required(translation.Globals.email_required)
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          translation.Globals.valid_email
        ),
      password: Yup.string()
        .required(translation.Globals.password_required)
        .min(6, translation.Globals.valid_password),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      const fcmtoken = getFromStorage(STORAGE_KEYS.fcmToken);

      let body = {
        key: formik.values.email,
        password: formik.values.password,
        appKey: new Date().toISOString(),
        deviceToken: fcmtoken || "1234",
        deviceType: "WEB",
      };

      try {
        const response = await loginMutation(body).unwrap();
        if (response?.statusCode === 200) {
          // showToast(response?.message || "Login Successfully.");
          setToStorage(STORAGE_KEYS.token, response?.data?.token || "");
          dispatch(
            setCredentials({
              user: response?.data,
              token: response?.data?.token || "",
            })
          );

          if (formik.values.remember) {
            setToStorage(STORAGE_KEYS.credentials, JSON.stringify(body));
          } else {
            removeFromStorage(STORAGE_KEYS.credentials);
          }

          navigate("/dashboard");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      }
    },
  });
  const handleClickLang = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElLang(event.currentTarget);
  };
  useEffect(() => {
    const data = getFromStorage(STORAGE_KEYS.credentials);
    if (data) {
      const rememberData = JSON.parse(`${data}`);
      formik.setFieldValue("password", rememberData?.password);
      formik.setFieldValue("email", rememberData?.key);
      formik.setFieldValue("remember", true);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  const change = () => {
    const body = document.body; // Use document.body directly to get the body element
    if (body) {
      const language = getFromStorage(STORAGE_KEYS.language);
      const isArabic = language === "ar";
      body.classList.remove(isArabic ? "english_lang" : "arabic_lang");
      body.classList.add(isArabic ? "arabic_lang" : "english_lang");
    }
  };
  useEffect(() => {
    change();
  }, [getLang]);

  return (
    <Box
      component="main"
      className="auth_module"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Loader isLoad={isLoading} />
      <Container className="auth_cntnr" maxWidth="xs">
        <Box
          sx={{
            mr: 2,
            position: "absolute",
            right: 0,
            top: 0,
            // ml: getLang === "ar" ? 2 : 0,
          }}
        >
          <IconButton
            className="notification_btn"
            aria-controls={openLang ? "basic-menu1" : undefined}
            aria-haspopup="true"
            aria-expanded={openLang ? "true" : undefined}
            onClick={handleClickLang}
            sx={{
              color: "white",
              fontSize: "medium",
            }}
          >
            Language : {getLang === "en" ? "English" : "Arabic"}
            <KeyboardArrowDownIcon />
            {/* <LanguageIcon /> */}
          </IconButton>
          <Menu
            id="basic-menu1"
            anchorEl={anchorElLang}
            open={openLang}
            onClose={() => setAnchorElLang(null)}
            MenuListProps={{
              "aria-labelledby": "basic-button1",
            }}
          >
            <MenuItem value="en" onClick={() => handleLangChange("en")}>
              English
            </MenuItem>
            <MenuItem value="ar" onClick={() => handleLangChange("ar")}>
              Arabic
            </MenuItem>
          </Menu>
        </Box>
        <Box
          m={0}
          className="auth_logo"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <figure className="logo_cntnr">
            <img src="/static/images/logo.png" alt="" />
          </figure>
        </Box>
        <div className="auth_box">
          <Box sx={{ mb: 3, textAlign: "center", color: "#1d1d1d" }}>
            <Typography variant="h5">
              {translation.Globals.login_to || ""}
            </Typography>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_label">
                {translation.Globals.email_address || ""}
              </Typography>
              <TextField
                className="text_field"
                hiddenLabel
                placeholder={translation.Globals.email_address || ""}
                fullWidth
                name="email"
                type="email"
                id="email"
                variant="outlined"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                helperText={formik.touched.email && formik.errors.email}
              />
            </FormControl>
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_label">
                {translation.Globals.password || ""}
              </Typography>
              <TextField
                className="text_field"
                hiddenLabel
                placeholder={translation.Globals.password || ""}
                fullWidth
                name="password"
                id="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                helperText={formik.touched.password && formik.errors.password}
                type={showPassword ? "text" : "password"}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="eye_btn" position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box className="remember_box">
                <FormControlLabel
                  control={<Checkbox />}
                  label={translation.Globals.remember_me}
                  checked={formik.values.remember}
                  name="remember"
                  onChange={formik.handleChange}
                />
              </Box>
              <Box
                className="anchor_link"
                onClick={() => navigate("/forgotpassword")}
              >
                <Typography>{translation.Globals.forgot_password}?</Typography>
              </Box>
            </Box>
            <Box sx={{ pt: 3 }}>
              <Button
                className="btn btn_primary"
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Sign In
              </Button>
            </Box>
          </form>
        </div>
      </Container>
    </Box>
  );
};

export default Login;
