import React, { ChangeEvent, useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";

import * as Yup from "yup";
import { Autocomplete } from "@react-google-maps/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import AddIcon from "@mui/icons-material/Add";
import { UploadMedia } from "../../utils/mediaUpload";
import { Loader, showError, showToast } from "../../constants";
import { isNumber, isString } from "../../utils/validations";
import { useFormik } from "formik";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

import {
  useEditAdvertiserByIdMutation,
  useLazyGetAdvertiserByIdQuery,
  usePostAdvertiserMutation,
} from "../../services/advertiser";
import { AdvertiserResponse } from "../../types/General";
import moment from "moment";
import useTranslation from "../../hooks/Translation";

const AddServiceProvider = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [frontDocImage, setFrontDocImage] = useState<string>("");
  const [backDocImage, setBackDocImage] = useState<string>("");
  const [image, setImage] = useState<string>("");
  const [error, setError] = useState(false);
  const [advertiserDetails, setAdvertiserDetails] =
    useState<AdvertiserResponse>();
  const [addAdvertiser] = usePostAdvertiserMutation();
  const [getAdvertiserDetails, { isLoading }] = useLazyGetAdvertiserByIdQuery();
  const [updateAdvertiser] = useEditAdvertiserByIdMutation();
  const [autocomplete, setAutocomplete] = useState(null);
  const translations = useTranslation() as any;
  const onLoad = (autocompleteObj: any) => {
    console.log("hello");
    setAutocomplete(autocompleteObj);
  };

  const onPlaceChanged = async () => {
    if (autocomplete) {
      let place = await (autocomplete as any).getPlace();
      console.log(place, "place");

      if (place && place.address_components) {
        let address = place.address_components;

        let state,
          city,
          country,
          zip = "";

        address.forEach(function (component: any) {
          let types = component.types;

          if (
            types.indexOf("locality") > -1 ||
            types.indexOf("administrative_area_level_3") > -1
          ) {
            city = component.long_name;
          }
          if (types.indexOf("postal_code") > -1) {
            zip = component.long_name;
          }
          if (types.indexOf("administrative_area_level_1") > -1) {
            state = component?.long_name || "";
          }
          if (types.indexOf("country") > -1) {
            country = component?.long_name || "";
          }
        });

        var lat = place.geometry.location.lat();
        var lng = place.geometry.location.lng();

        formik.setFieldValue("address", `${place?.formatted_address}`);
        formik.setFieldValue("city", city || "");
        formik.setFieldValue("country", country || "");
        formik.setFieldValue("state", state || "");
        formik.setFieldValue("zipCode", zip || "");
        formik.setFieldValue("lat", lat || "");
        formik.setFieldValue("lng", lng || "");
      }
    }
  };
  const getAdvertiserDetail = async (id: string) => {
    try {
      const response = await getAdvertiserDetails({
        id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setAdvertiserDetails(response?.data);
        setImage(response?.data?.image || "");
        setFrontDocImage(response?.data?.documentFrontImage || "");
        setBackDocImage(response?.data?.documentBackImage || "");
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    imageType: string
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      if (imageType === "profileImage") {
        setImage(res?.data);
      } else if (imageType === "documentFrontImage") {
        setFrontDocImage(res?.data);
      } else {
        setBackDocImage(res?.data);
      }
    } else {
      showError(res?.message);
    }
  };

  const handleChangePhone = (phone: any, country: any) => {
    console.log("country: ", country);
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    formik.setFieldValue(
      "countryCode",
      country?.dialCode.includes("+") ? "" : "+" + country?.dialCode
    );
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: advertiserDetails?.email || "",
      fullName: advertiserDetails?.fullName || "",
      phone: advertiserDetails?.phone || "",
      countryCode: advertiserDetails?.countryCode || "+91",
      address: advertiserDetails?.address || "",
      city: advertiserDetails?.city || "",
      state: advertiserDetails?.state || "",
      country: advertiserDetails?.country || "",
      lat: 0,
      lng: 0,
      documentName: advertiserDetails?.documentName || "",
      documentExpiryDate: advertiserDetails?.documentExpiryDate
        ? advertiserDetails.documentExpiryDate.split("T")[0]
        : "",

      zipCode: advertiserDetails?.zipCode || "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(translations.Globals.email_required)
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          translations.Globals.valid_email

        ),
      fullName: Yup.string()
        .required(translations.Globals.full_name_req)
        .min(2, translations.Globals.min_2)
        .max(80, translations.Globals.min_2),

      phone: Yup.string()
        .required(translations.Globals.phone_required)
        .min(6, translations.Globals.phone_6)
        .max(16, translations.Globals.phone_16),

      address: Yup.string()
        .required(translations.Globals.address_req)
        .min(2, translations.Globals.min_2)
        .max(100, translations.Globals.max_100),
      city: Yup.string()
        .required(translations.Globals.city_req)
        .min(2, translations.Globals.min_2)
        .max(100, translations.Globals.max_100),
      state: Yup.string()
        .required(translations.Globals.state_req)
        .min(2, translations.Globals.min_2)
        .max(100, translations.Globals.max_100),
      country: Yup.string()
        .required(translations.Globals.country_req)
        .min(2, translations.Globals.min_2)
        .max(100,  translations.Globals.max_100),
      zipCode: Yup.string()
        .required(translations.Globals.field_req)
        .min(4, translations.Globals.min_4)
        .max(12,  translations.Globals.max_12),
      documentName: Yup.string()
        .required(translations.Globals.field_req)
        .min(2, translations.Globals.min_2)
        .max(80, translations.Globals.max_80),

      documentExpiryDate: Yup.string().required(translations.Globals.field_req),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);
      console.log(values.documentExpiryDate, "date");
      let body = {
        ...(values.email !== advertiserDetails?.email
          ? { email: values.email }
          : {}),
        ...(values.phone !== advertiserDetails?.phone ||
        values.countryCode !== advertiserDetails?.countryCode
          ? { phone: values.phone }
          : {}),
        ...(values.phone !== advertiserDetails?.phone ||
        values.countryCode !== advertiserDetails?.countryCode
          ? { countryCode: values.countryCode }
          : {}),

        image: image,

        fullName: values.fullName,

        address: values.address,
        city: values.city,
        country: values.country,
        state: values.city,
        latitude: values.lat,
        longitude: values.lng,
        zipCode: values.zipCode,
        documentName: values.documentName,
        documentExpiryDate: values.documentExpiryDate,
        documentFrontImage: frontDocImage,
        documentBackImage: backDocImage,
        appKey: new Date().toISOString(),
      };
      if (!frontDocImage) {
        showError(translations.Globals.front_img_req);
        return;
      } else if (!backDocImage) {
        showError(translations.Globals.back_img_req);
        return;
      }
      console.log("body", body);
      if (id) {
        try {
          const response = await updateAdvertiser({ id, body }).unwrap();
          if (response?.statusCode === 200) {
            showToast(translations.Globals.advertiser_update);
            navigate("/manage-advertisers", { replace: true });
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      } else {
        try {
          const response = await addAdvertiser(body).unwrap();
          if (response?.statusCode === 200) {
            showToast(translations.Globals.advertiser_create);
            navigate("/manage-advertisers", { replace: true });
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      }
    },
  });

  useEffect(() => {
    if (id) {
      getAdvertiserDetail(id);
    }
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          {id ? (
            <h1 className="mn_hdng">
              {translations.add_advertiser.edit || ""}
            </h1>
          ) : (
            <h1 className="mn_hdng">{translations.add_advertiser.add || ""}</h1>
          )}
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-advertisers");
            }}
          >
            {translations.Globals.back || ""}
          </Button>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className="custom_label">
                    {translations.add_advertiser.image || ""}
                  </Typography>
                  {image ? (
                    <div className="upload_image_preview">
                      <CardMedia component="img" image={image} alt="photo" />
                      <CancelIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          setImage("");
                        }}
                      />
                    </div>
                  ) : (
                    <Box className="upload_image">
                      <label htmlFor="icon-button-file">
                        <Input
                          sx={{ display: "none" }}
                          id="icon-button-file"
                          type="file"
                          inputProps={{
                            accept: "image/png,image/jpeg",
                          }}
                          onChange={(e) =>
                            handleImageUpload(
                              e as ChangeEvent<HTMLInputElement>,
                              "profileImage"
                            )
                          }
                        />
                        <Button component="span" className="upload_image_btn">
                          <img
                            src={
                              image
                                ? image
                                : "/static/images/user_placeholder.png"
                            }
                            alt=""
                          />
                          <CameraAltIcon />
                        </Button>
                      </label>
                    </Box>
                  )}
                  {error && !image ? (
                    <h6 className="err_msg">{translations.Globals.field_req}</h6>
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translations.add_advertiser.fname || ""}
                  </Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type={"text"}
                    name="fullName"
                    inputProps={{ maxLength: 25 }}
                    variant="outlined"
                    fullWidth
                    placeholder="Full Name"
                    id="fullName"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.fullName}
                    helperText={
                      formik.touched.fullName && formik.errors.fullName
                    }
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translations.add_advertiser.email || ""}
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"email"}
                    name="email"
                    variant="outlined"
                    fullWidth
                    placeholder="Email"
                    id="email"
                    className="text_field"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translations.add_advertiser.phone || ""}
                  </Typography>
                  <PhoneInput
                    enableSearch
                    value={formik.values.countryCode + formik.values.phone}
                    country={"us"}
                    inputClass="phoneInput"
                    inputStyle={{ width: "100%" }}
                    buttonClass="phoneBtn"
                    placeholder="Phone Number"
                    onChange={(phone, country) =>
                      handleChangePhone(phone, country)
                    }
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <h6 className="err_msg">
                      {formik.touched.phone && formik.errors.phone}
                    </h6>
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid item xs={12} className="heading">
                  <Typography className="custom_label" variant="h5">
                    {translations.add_advertiser.address || ""}
                  </Typography>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translations.add_advertiser.address || ""}
                  </Typography>
                  <Autocomplete
                    onLoad={onLoad}
                    onPlaceChanged={() => onPlaceChanged()}
                  >
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="address"
                      variant="outlined"
                      fullWidth
                      placeholder="Address "
                      className="text_field"
                      id="address"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.address}
                      helperText={
                        formik.touched.address && formik.errors.address
                      }
                    />
                  </Autocomplete>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translations.add_advertiser.city || ""}
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="city"
                    variant="outlined"
                    fullWidth
                    placeholder="City"
                    className="text_field"
                    id="city"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.city}
                    helperText={formik.touched.city && formik.errors.city}
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translations.add_advertiser.state || ""}
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="state"
                    variant="outlined"
                    fullWidth
                    placeholder="State"
                    className="text_field"
                    id="state"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.state}
                    helperText={formik.touched.state && formik.errors.state}
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translations.add_advertiser.country || ""}
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="country"
                    variant="outlined"
                    fullWidth
                    placeholder="Country"
                    className="text_field"
                    id="country"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.country}
                    helperText={formik.touched.country && formik.errors.country}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translations.add_advertiser.zip || ""}
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="zipCode"
                    variant="outlined"
                    fullWidth
                    placeholder="PIN Code/Zipcode"
                    className="text_field"
                    id="zipCode"
                    inputProps={{ maxLength: 12 }}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isNumber(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.zipCode}
                    helperText={formik.touched.zipCode && formik.errors.zipCode}
                  />
                </Grid>

                <Grid item xs={12} className="heading">
                  <Typography className="custom_label" variant="h5">
                    {translations.add_advertiser.upload_docs || ""}
                  </Typography>
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translations.add_advertiser.doc_name || ""}
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="documentName"
                    variant="outlined"
                    fullWidth
                    placeholder="Name of the Document"
                    className="text_field"
                    id="documentName"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.documentName}
                    helperText={
                      formik.touched.documentName && formik.errors.documentName
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translations.add_advertiser.expiry || ""}
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"date"}
                    name="documentExpiryDate"
                    variant="outlined"
                    fullWidth
                    placeholder="Expiry Date"
                    className="text_field"
                    id="documentExpiryDate"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        console.log(val.target.value);
                        const date = val.target.value;
                        formik.handleChange(val);
                        // formik.setFieldValue("documentExpiryDate",date)
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.documentExpiryDate}
                    helperText={
                      formik.touched.documentExpiryDate &&
                      formik.errors.documentExpiryDate
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: new Date().toISOString().split("T")[0],
                      max: "3000-12-31",

                      // readOnly: true,

                      // Set min to today's date
                    }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12} />
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Typography className="custom_label">
                    {" "}
                    {translations.add_advertiser.front || ""}
                  </Typography>
                  {frontDocImage ? (
                    <div className="upload_image_preview2">
                      <CardMedia
                        component="img"
                        image={frontDocImage}
                        alt="photo"
                      />
                      <CancelIcon
                        onClick={() => {
                          setFrontDocImage("");
                        }}
                      />
                    </div>
                  ) : (
                    <Box className="upload_image_bnr">
                      <label htmlFor="front-doc">
                        <Input
                          sx={{ display: "none" }}
                          id="front-doc"
                          type="file"
                          inputProps={{
                            accept: "image/png,image/jpeg",
                          }}
                          onChange={(e) =>
                            handleImageUpload(
                              e as ChangeEvent<HTMLInputElement>,
                              "documentFrontImage"
                            )
                          }
                        />
                        <Button component="span" className="upload_image_btn">
                          <AddIcon />
                        </Button>
                      </label>
                    </Box>
                  )}
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Typography className="custom_label">
                    {translations.add_advertiser.back || ""}
                  </Typography>
                  {backDocImage ? (
                    <div className="upload_image_preview2">
                      <CardMedia
                        component="img"
                        image={backDocImage}
                        alt="photo"
                      />
                      <CancelIcon
                        onClick={() => {
                          setBackDocImage("");
                        }}
                      />
                    </div>
                  ) : (
                    <Box className="upload_image_bnr">
                      <label htmlFor="back-doc">
                        <Input
                          sx={{ display: "none" }}
                          id="back-doc"
                          type="file"
                          inputProps={{
                            accept: "image/png,image/jpeg",
                          }}
                          // onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          //   const files = (event.target as HTMLInputElement)
                          //     .files;
                          //   if (files && files[0].type.includes("image")) {
                          //     setBackDocImage(URL.createObjectURL(files[0]));
                          //   }
                          // }}
                          onChange={(e) =>
                            handleImageUpload(
                              e as ChangeEvent<HTMLInputElement>,
                              "documentBackImage"
                            )
                          }
                        />
                        <Button component="span" className="upload_image_btn">
                          {/* <img
                                src={
                                  image
                                    ? image
                                    : "/static/images/user_placeholder.png"
                                }
                                alt=""
                              /> */}
                          <AddIcon />
                        </Button>
                      </label>
                    </Box>
                  )}
                </Grid>
              </Grid>
              <div className="form_btn">
                <Button
                  size="large"
                  type="submit"
                  className="btn btn_primary"
                  onClick={() => setError(true)}
                >
                  {translations.Globals.save || ""}
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default AddServiceProvider;
