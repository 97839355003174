import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { useLazyGetChallengeByIdQuery } from "../../services/advertiser";
import { Loader, showError } from "../../constants";
import { ChallengeDetailsType } from "../../types/General";
import moment from "moment";
import useTranslation from "../../hooks/Translation";

const ChallengeDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const [getChallenges, { isLoading }] = useLazyGetChallengeByIdQuery();
  const navigate = useNavigate();
  const [challenge, setChallenge] = useState<ChallengeDetailsType>();

  const fetchChallenges = async (id: string | undefined) => {
    try {
      const response: any = await getChallenges({
        id,
      }).unwrap();
      console.log(response?.data);
      if (response?.statusCode === 200) {
        setChallenge(response?.data);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  useEffect(() => {
    if (id) {
      fetchChallenges(id);
    }
  }, []);
const translations=useTranslation()as any
  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translations.manage_challenge.challeneg_details||""}</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              {
                state.page === "User"
                  ? navigate(`/manageusers/userdetails/${challenge?.userId}`)
                  : navigate("/manage-challenges");
              }
            }}
          >
            {translations.Globals.back||""}
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={3} className="view_box view_box_list">
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">{translations.manage_challenge.user_image||""}</Typography>

                  <figure className="profile_img">
                    <img
                      src={
                        challenge?.userImage ||
                        "/static/images/user_placeholder.png"
                      }
                      alt=""
                    />
                  </figure>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12} />
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">{translations.manage_challenge.username||""}</Typography>
                  <Typography component="p">
                    {challenge?.userName || ""}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">{translations.manage_challenge.email||""}</Typography>
                  <Typography component="p">
                    {challenge?.email || ""}
                  </Typography>
                </Box>
              </Grid>

              {/* <Grid item lg={6} md={6} sm={6} xs={12} /> */}
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">{translations.manage_challenge.phone||""}</Typography>
                  <Typography component="p">
                    {challenge?.countryCode
                      ? (challenge?.countryCode.includes("+") ? "" : "+") +
                        challenge?.countryCode
                      : null}{" "}
                    {challenge?.phone || "-"}
                  </Typography>
                </Box>
              </Grid>
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Created Date</Typography>
                  <Typography component="p">    {moment(challenge[0]?.createdAt).format(
                              "DD/MM/YYYY"
                            )}</Typography>
                </Box>
              </Grid> */}

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="heading">
                  <Typography component="h5">{translations.manage_challenge.challeneg_details||""}</Typography>
                </Box>
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Box>
                  <Typography component="h5"></Typography>

                  {challenge?.uploads[0]?.type === "VIDEO" ? (
                    <video
                      autoPlay
                      muted
                      loop
                      playsInline
                      className="hm_vd"
                      preload="yes"
                      key={challenge?._id}
                    >
                      <source
                        src={
                          //  challenge?.uploads[0]?.link ||
                          "/static/images/post.png"
                        }
                        type="video/mp4"
                      />
                    </video>
                  ) : (
                    <figure className="challenge_img">
                      <img
                        src={
                          challenge?.uploads[0]?.link ||
                          "/static/images/post.png"
                        }
                        alt=""
                      />
                    </figure>
                  )}
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12} />
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">{translations.manage_challenge.title||""}</Typography>
                  <Typography component="p">
                    {challenge?.title || "-"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">{translations.manage_challenge.category||""}</Typography>
                  <Typography component="p">
                    {challenge?.categoryName || "-"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">{translations.manage_challenge.no_of_entries||""}</Typography>
                  <Typography component="p">
                    {challenge?.noOfEntries || 0}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">{translations.manage_challenge.location||""}</Typography>
                  <Typography component="p">
                    {challenge?.completeAddress || ""}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">{translations.manage_challenge.start_time||""}</Typography>
                  <Typography component="p">
                    {moment(challenge?.startTime).format("DD MMM YYYY h:mm A")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">{translations.manage_challenge.end_time||""}</Typography>
                  <Typography component="p">
                    {moment(challenge?.endTime).format("DD MMM YYYY h:mm A")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box>
                  <Typography component="h5">{translations.manage_challenge.desc||""}</Typography>
                  <Typography component="p">
                    {challenge?.description || ""}
                  </Typography>
                </Box>
              </Grid>

              {challenge?.challengeList?.length ? (
                <>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box className="heading">
                      <Typography component="h5">{translations.manage_challenge.list||""}</Typography>
                    </Box>
                  </Grid>
                  {challenge?.challengeList?.map((item: any, index: any) => (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box>
                        {/* <Typography component="h5">Title</Typography> */}
                        <Typography component="p">
                          {index + 1}&nbsp;&nbsp; {item.name}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </>
              ) : null}
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ChallengeDetails;
