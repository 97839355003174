import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Box, Button, Card, Tab, Tabs, Typography } from "@mui/material";

import MainContainer from "../../../layout/MainContainer";
import "../Users.scss";
import {
  Challenges,
  Details,
  Journeys,
  PostsAndStories,
  UserStories,
} from "../../../features";
import {
  useLazyGetUserByIdQuery,
  useLazyGetUserPostsQuery,
  useLazyGetUserReportedPostsQuery,
  useLazyGetUserStoriesQuery,
} from "../../../services/user";
import {
  PostResponse,
  ReportedPosts,
  StoryResponse,
  UserResponse,
} from "../../../types/General";
import { Loader, showError } from "../../../constants";
import useTranslation from "../../../hooks/Translation";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UserDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
const translation=useTranslation() as any
  const [value, setValue] = useState(0);
  const [userData, setUserData] = useState<UserResponse>();
  const [userPosts, setUserPosts] = useState<PostResponse[]>([]);
  const [reportedPosts, setReportedPosts] = useState<ReportedPosts[]>([]);
  const [userStories, setUserStories] = useState<StoryResponse[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [getUserById, { isLoading }] = useLazyGetUserByIdQuery();
  const [getUserPosts] = useLazyGetUserPostsQuery();
  const [getUserStories] = useLazyGetUserStoriesQuery();
  const [getReportedPosts] = useLazyGetUserReportedPostsQuery();

  let totalPages = Math.ceil(totalCount / 10);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getUserDetail = async (id: string) => {
    try {
      const response = await getUserById({
        id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setUserData(response?.data);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const getPosts = async (id: string) => {
    try {
      const response = await getUserPosts({
        id: id,
        page: page,
        limit: 10,
      }).unwrap();
      if (response?.statusCode === 200) {
        setUserPosts(response?.data?.post || []);
        setTotalCount(response?.data?.count);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };
  const getReportedPostsList = async (id: string) => {
    try {
      const response = await getReportedPosts({
        id: id,
        page: page,
        limit: 10,
      }).unwrap();
      if (response?.statusCode === 200) {
        setReportedPosts(response?.data?.reportPost || []);
        setTotalCount(response?.data?.count);
      } else {
        setReportedPosts([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const getStories = async (id: string) => {
    try {
      const response = await getUserStories({
        id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setUserStories(response?.data?.story || []);
        setTotalCount(response?.data?.storyCount);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id) {
      if (value === 0) {
        getUserDetail(id);
      } else if (value === 4) {
        getStories(id);
      }
    }
  }, [value, page]);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translation.Globals.view_profile}</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manageUsers");
            }}
          >
            {translation.Globals.back||""}
          </Button>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="flx_sc">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab label={translation.manage_users_details.user_details||""} {...a11yProps(0)} />
                <Tab label={translation.manage_users_details.active_comp_journey||""} {...a11yProps(1)} />
                <Tab
                  label={translation.manage_users_details.active_comp_challenge||""}
                  {...a11yProps(2)}
                />
                <Tab label={translation.manage_users_details.posts||""} {...a11yProps(3)} />
                <Tab label={translation.manage_users_details.stories||""} {...a11yProps(4)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Details userData={userData} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Journeys />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Challenges />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <PostsAndStories
                getPosts={getPosts}
                getReportedPostsList={getReportedPostsList}
                userPosts={userPosts}
                reportedPosts={reportedPosts}
                page={page}
                totalPages={totalPages}
                onPageChange={onPageChange}
                userId={id}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <UserStories
                userStories={userStories}
                page={page}
                totalPages={totalPages}
                onPageChange={onPageChange}
                getUserStories={getStories}
                
              />
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default UserDetails;
