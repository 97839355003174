import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import { UserResponse } from "../../types/General";
import useTranslation from "../../hooks/Translation";

type props = {
  userData: UserResponse | undefined;
};

const Details = ({ userData }: props) => {
  const translation=useTranslation()as any
  return (
    <Card className="cards">
      <CardContent sx={{ p: 1 }}>
        <Grid container spacing={2} className="view_box">
          <Grid item lg={2} md={2} sm={6} xs={12}>
            <figure className="profile_img">
              <img
                src={userData?.image || "/static/images/user_placeholder.png"}
                alt=""
              />
            </figure>
          </Grid>
          <Grid item xs={10} className="view_box_list">
            <Grid container spacing={3}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">{translation.table_heads.username}</Typography>
                  <Typography component="p">
                    {userData?.userName || "-"}
                  </Typography>
                </Box>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">{translation.table_heads.phone}</Typography>
                  <Typography component="p">
                    {userData?.countryCode
                      ? (userData?.countryCode.includes("+") ? "" : "+") +
                        userData?.countryCode
                      : null}{" "}
                    {userData?.phone || "-"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">{translation.table_heads.email}</Typography>
                  <Typography component="p">
                    {userData?.email || "-"}
                  </Typography>
                </Box>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">{translation.table_heads.account_status}</Typography>
                  <Typography component="p">
                    {userData?.isBlocked ? translation.Globals.blocked : translation.Globals.active}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">{translation.Globals.address}</Typography>
                  <Typography component="p">-</Typography>
                </Box>
              </Grid>
              {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box>
                  <Typography component="h5">Bio</Typography>
                  <Typography component="p">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book
                  </Typography>
                </Box>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Details;
