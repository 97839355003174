import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Card,
  IconButton,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { PostResponse, ReportedPosts } from "../../types/General";
import {
  useLazyChangePostStatusQuery,
  useLazyDeletePostByIdQuery,
  useLazyDeleteReportedByIdQuery,
  useLazyGetPostsQuery,
  useLazyGetReportedPostsQuery,
} from "../../services/posts";
import { Loader, showError } from "../../constants";

import moment from "moment";
import { handleDelete, updateStatus, updateStatusNew } from "../../utils/commonFunctions";
import { Pagination } from "../../components";
import WarnModal from "../../components/WarnModal";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";
import { isValidInput } from "../../utils/validations";
import SearchBar from "../../components/SearchBar";
import useTranslation from "../../hooks/Translation";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageContent = () => {
  const navigate = useNavigate();
  const userData = useAuth();

  const [selectedId, setSelectedId] = useState<string>("");
  const [value, setValue] = useState<number>(0);

  const [totalCount, setTotalCount] = useState<number>(0);

  const [open, setOpen] = useState<boolean>(false);

  const [page, setPage] = useState(1);
  const [posts, setPosts] = useState<PostResponse[]>([]);
  const [repotedPosts, setReportedPosts] = useState<ReportedPosts[]>([]);
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [getPosts, { isLoading }] = useLazyGetPostsQuery();
  const [getReportedPosts] = useLazyGetReportedPostsQuery();

  const [updatePostStatus] = useLazyChangePostStatusQuery();
  const [deletePost] = useLazyDeletePostByIdQuery();
  const [deleteReportedPost] = useLazyDeleteReportedByIdQuery();

  const label = { inputProps: { "aria-label": "Switch demo" } };

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const checkPermission = () => {
    const permission = userData?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Content/Posts"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  const getPostsList = async () => {
    try {
      const response = await getPosts({
        query:debouncedSearchTerm.trim(),
        page: page,
        limit: 10,
      }).unwrap();
      if (response?.statusCode === 200) {
        setPosts(response?.data?.post || []);
        setTotalCount(response?.data?.count);
      } else {
        setPosts([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  const getReportedPostsList = async () => {
    try {
      console.log(debouncedSearchTerm)
      const response = await getReportedPosts({
        query:debouncedSearchTerm.trim(),
        page: page,
        limit: 10,
      }).unwrap();
      if (response?.statusCode === 200) {
        setReportedPosts(response?.data?.reportPost || []);
        setTotalCount(response?.data?.count);
      } else {
        setReportedPosts([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (value === 0) {
      getPostsList();
    } else {
      getReportedPostsList();
    }
  }, [page, value,searchTerm,debouncedSearchTerm]);

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);
  const translations=useTranslation()as any

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translations.sub_admin.manage_content||""}</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="flx_sc">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab label={translations.manage_content.all_post||""} {...a11yProps(0)} />
                <Tab label={translations.manage_content.reported_post||""} {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Box className="cards_header">
              <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
                 value={searchTerm}
              onCross={() => setSearchTerm("")}
             onChange={(val: any) => {
               if (isValidInput(val.target.value)) {
              setSearchTerm(val.target.value);
            }
          }
          }
        />
              </Box>
              <TableContainer className="table_container">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">{translations.table_heads.sno||""}</TableCell>
                      <TableCell>{translations.table_heads.post||""}</TableCell>
                      <TableCell>{translations.table_heads.post_description||""}</TableCell>
                      <TableCell>{translations.table_heads.user_details||""}</TableCell>
                      <TableCell>{translations.table_heads.date_time||""}</TableCell>
                      {/* <TableCell>Upload Time</TableCell> */}
                      <TableCell>{translations.table_heads.status||""}</TableCell>
                      <TableCell>{translations.table_heads.actions||""}</TableCell>
                    </TableRow>
                  </TableHead>

                  {posts?.length ? (
                    posts?.map((item, i) => {
                      return (
                        <TableBody key={item?._id}>
                          <TableRow>
                            <TableCell align="center">
                              {(page - 1) * 10 + i + 1}
                            </TableCell>
                            <TableCell>
                              {item?.uploads[0]?.type === "IMAGE" ? (
                                <figure className="bnr_img">
                                  <img
                                    src={
                                      item?.uploads[0]?.link ||
                                      "/static/images/post.png"
                                    }
                                    alt=""
                                  />
                                </figure>
                              ) : (
                                <figure className="bnr_img">
                                  <img
                                    src={
                                      item?.uploads[0]?.thumbnail ||
                                      "/static/images/post.png"
                                    }
                                    alt=""
                                  />
                                </figure>
                              )}
                            </TableCell>
                            <TableCell>
                              {item?.comment?.length > 30
                                ? item?.comment.slice(0, 30) + "..."
                                : item?.comment}
                            </TableCell>
                            <TableCell>
                              <Typography variant="h6" fontSize={14}>
                                {item?.userName || "-"}
                              </Typography>
                              <Typography variant="h6" fontSize={14}>
                                {item?.userEmail || "-"}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Typography variant="h6" fontSize={14}>
                                {moment(item?.createdAt).format("DD/MM/YYYY")}
                              </Typography>

                              <Typography variant="h6" fontSize={14}>
                                {moment(item?.createdAt).format("h:mm A")}
                              </Typography>
                            </TableCell>

                            <TableCell>
                              <Switch
                                {...label}
                                size="small"
                                checked={!item?.isBlocked}
                                onChange={() =>
                                  updateStatus(
                                    item?._id,
                                    !item?.isBlocked,
                                    updatePostStatus,
                                    setPosts,
                                    posts
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <Box className="table_actions">
                                <IconButton
                                  onClick={() =>
                                    navigate(
                                      `/manage-content/details/${item?._id}`,
                                      { state: { page: "POST" } }
                                    )
                                  }
                                >
                                  <VisibilityIcon />
                                </IconButton>
                                {hidePermission?.isDelete ||
                                userData?.role === "ADMIN" ? (
                                  <IconButton>
                                    <DeleteIcon
                                      onClick={() => {
                                        setOpen(true);
                                        setSelectedId(item?._id);
                                      }}
                                    />
                                  </IconButton>
                                ) : null}
                              </Box>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })
                  ) : (
                    <TableBody>
                      <TableCell
                        align="center"
                        colSpan={10}
                        sx={{ color: "#051140" }}
                      >
                        {translations.manage_content.not_found||""}
                      </TableCell>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <Box className="cards_header">
                  <SearchBar
                    searchTerm={searchTerm}
                    setDebouncedSearchTerm={setDebouncedSearchTerm}
                    value={searchTerm}
                    onCross={() => setSearchTerm("")}
                    onChange={(val: any) => {
                      if (isValidInput(val.target.value)) {
                        setSearchTerm(val.target.value);
                      }
                    }}
                  />
                </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">{translations.table_heads.sno||""}</TableCell>
                      <TableCell>{translations.table_heads.post||""}</TableCell>
                      <TableCell>{translations.table_heads.post_description||""}</TableCell>
                      <TableCell>{translations.table_heads.reported_by||""}</TableCell>
                      <TableCell>{translations.table_heads.date_time||""}</TableCell>
                      <TableCell>{translations.table_heads.status||""}</TableCell>
                      <TableCell>{translations.table_heads.actions||""}</TableCell>
                    </TableRow>
                  </TableHead>

                  {repotedPosts?.length ? (
                    repotedPosts?.map((item, i) => {
                      return (
                        <TableBody key={item?._id}>
                          <TableRow>
                            <TableCell align="center">
                              {(page - 1) * 10 + i + 1}
                            </TableCell>
                            <TableCell>
                              {item?.post?.uploads[0]?.type === "IMAGE" ? (
                                <figure className="bnr_img">
                                  <img
                                    src={
                                      item?.post?.uploads[0]?.link ||
                                      "/static/images/post.png"
                                    }
                                    alt=""
                                  />
                                </figure>
                              ) : (
                                <figure className="bnr_img">
                                  <img
                                    src={
                                      item?.post?.uploads[0]?.thumbnail ||
                                      "/static/images/post.png"
                                    }
                                    alt=""
                                  />
                                </figure>
                              )}
                            </TableCell>
                            <TableCell>
                              {item?.post?.comment?.length > 30
                                ? item?.post?.comment.slice(0, 30) + "..."
                                : item?.post?.comment || "-"}
                            </TableCell>

                            <TableCell>
                              <Typography variant="h6" fontSize={14}>
                                {item?.userName || ""}
                              </Typography>
                              <Typography variant="h6" fontSize={14}>
                                {item?.userEmail || ""}
                              </Typography>
                            </TableCell>
                            {/* <TableCell></TableCell> */}

                            <TableCell>
                              <Typography variant="h6" fontSize={14}>
                                {moment(item?.createdAt).format("DD/MM/YYYY")}
                              </Typography>

                              <Typography variant="h6" fontSize={14}>
                                {moment(item?.createdAt).format("h:mm A")}
                              </Typography>
                            </TableCell>
                            
                            <TableCell>
                              <Switch
                                {...label}
                                size="small"
                                
                                checked={!item?.isBlocked}

                                onChange={() =>
                                  updateStatusNew(
                                    item?.post?._id,
                                    item?._id,
                                    !item?.isBlocked,
                                    updatePostStatus,
                                    setReportedPosts,
                                    repotedPosts
                                    
                                  )
                                }
                              />
                            </TableCell>

                            {/* <TableCell>
                              <Switch
                                {...label}
                                size="small"
                                checked={!item?.isBlocked}
                                onChange={() =>
                                  updateStatus(
                                    item?._id,
                                    !item?.isBlocked,
                                    updatePostStatus,
                                    setPosts,
                                    posts
                                  )
                                }
                              />
                            </TableCell> */}
                            <TableCell>
                              <Box className="table_actions">
                                <IconButton
                                  onClick={() =>
                                    navigate(
                                      `/manage-content/details/${item?.post?._id}`,
                                      { state: { page: "POST" } }
                                    )
                                  }
                                >
                                  <VisibilityIcon />
                                </IconButton>
                                {hidePermission?.isDelete ||
                                userData?.role === "ADMIN" ? (
                                  <IconButton>
                                    <DeleteIcon
                                      onClick={() => {
                                        setOpen(true);
                                        setSelectedId(item?._id);
                                      }}
                                    />
                                  </IconButton>
                                ) : null}
                              </Box>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })
                  ) : (
                    <TableBody>
                      <TableCell
                        align="center"
                        colSpan={10}
                        sx={{ color: "#051140" }}
                      >
                        {translations.manage_content.not_found||""}
                      </TableCell>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
      <Pagination
        module={value === 0 ? posts : repotedPosts}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
      {value === 0 ? (
        <WarnModal
          setOpen={setOpen}
          open={open}
          name={translations.table_heads.post}
          handleDelete={() =>
            handleDelete(deletePost, selectedId, getPostsList)
          }
        />
      ) : (
        <WarnModal
          setOpen={setOpen}
          open={open}
          name={translations.table_heads.post}
          handleDelete={() =>
            handleDelete(deleteReportedPost, selectedId, getReportedPostsList)
          }
        />
      )}
    </MainContainer>
  );
};

export default ManageContent;
