import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLazyDeleteRewardsQuery, useLazyGetRewardsQuery, useLazyRewardsStatusChangeQuery } from "../../services/advertiser";
import { getFromStorage, showError } from "../../constants";
import { isValidInput } from "../../utils/validations";
import { handleDelete, updateStatus } from "../../utils/commonFunctions";
import WarnModal from "../../components/WarnModal";
import { Pagination } from "../../components";
import useTranslation from "../../hooks/Translation";
import { Reward } from "../../types/General";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";

const ManageRewards = () => {
  const navigate = useNavigate();
  const userData=useAuth()
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const[statusChange]=useLazyRewardsStatusChangeQuery()
  const [open, setOpen] = useState<boolean>(false);
const [selectId,setSelectedId]=useState<string>("")
const isArb=getFromStorage("LANGUAGE")
const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const [getRewards, { isLoading }] = useLazyGetRewardsQuery();

  const [rewards, setRewards] = useState<Reward[]>([]);

  function createData(Name: string) {
    return { Name };
  }

  const changeStatus=async()=>{
      try{
        

      }catch(error:any){
        showError(error?.data?.message ||"")
      }
  }

  const checkPermission = () => {
    const permission = userData?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Rewards",
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  const fetchRewards = async () => {
    try {
      const response: any = await getRewards({
        limit: 10,
        page: page,
        search: debouncedSearchTerm.trim(),
      });
      console.log(response?.data?.data?.rewards, "response");
      if (response?.data?.statusCode === 200) {
        setRewards(response?.data?.data?.rewards);
        setTotalCount(response?.data?.data?.count);
      } else {
        setRewards([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [createData("Adventurous")];
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  let totalPages = Math.ceil(totalCount / 10);

  const [deleteById]=useLazyDeleteRewardsQuery()

  useEffect(() => {
    fetchRewards();
  }, [debouncedSearchTerm, searchTerm, page]);

  const translations=useTranslation()as any

  useEffect(()=>{
    if(userData && userData?.permission?.length){
      checkPermission()
    }
  },[userData])

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translations.manage_rewards.manage_rewards||""}</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="cards_header">
              <SearchBar
                searchTerm={searchTerm}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                value={searchTerm}
                onCross={() => setSearchTerm("")}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
              <Box className="cards_header_right">
                {hidePermission?.isEdit || userData?.role==="ADMIN"?(

                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/manage-rewards/add")}
                >
                  {translations.manage_rewards.add||""}
                </Button>
                ):null}
              </Box>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{translations.table_heads.sno||""}</TableCell>
                    <TableCell>{translations.table_heads.badge_image||""}</TableCell>
                    <TableCell> {translations.table_heads.name||""}</TableCell>
                    <TableCell>{translations.table_heads.points_earned||""}</TableCell>
                    <TableCell>{translations.table_heads.no_of_journey||""}</TableCell>
                    <TableCell>{translations.table_heads.no_of_post||""}</TableCell>
                    <TableCell>{translations.table_heads.no_of_challenge||""}</TableCell>
                    <TableCell>{translations.table_heads.status||""}</TableCell>
                    <TableCell>{translations.table_heads.actions||""}</TableCell>
                  </TableRow>
                </TableHead>

                {rewards?.length ? (
                  rewards?.map((item, index) => (
                    <TableBody key={index}>
                      <TableRow>
                        <TableCell>
                        {(page - 1) * 10 + index + 1}
                        </TableCell>

                        <TableCell>
                        <figure className="bnr_img">
                                <img
                                  src={
                                    item?.image ||
                                    "/static/images/post.png"
                                  }
                                  alt=""
                                />
                              </figure>
                        </TableCell>
                        <TableCell>
                          {isArb==="ar"?item?.title_ar:item?.title}
                        </TableCell>
                        <TableCell>
                          {item?.pointsEarned}
                        </TableCell>
                        <TableCell>
                          {item?.noOfJourneys}
                        </TableCell>
                        <TableCell>
                          {item?.noOfPosts}
                        </TableCell>
                        <TableCell>
                          {item?.noOfChallenges}
                        </TableCell>
                        <TableCell>
                          <Switch 
                             onChange={() => {
                                
                              updateStatus(
                                item?._id,
                                !item?.isBlocked,
                                statusChange,
                                setRewards,
                                rewards
                              );


                            }}
                          {...label} defaultChecked size="small" />
                          </TableCell>
                          <TableCell>
                          <Box className="table_actions">

                            {hidePermission?.isEdit || userData?.role==="ADMIN"? (

                  <IconButton
                    onClick={() =>
                      navigate("/manage-rewards/edit/"+item?._id, 
                      
                      )
                    }
                  >
                    <ModeEditIcon />
                  </IconButton>
                            ):null}

                    {hidePermission?.isDelete||userData?.role==="ADMIN"?(

                  <IconButton 
                  // onClick={()=>{
                  //   setOpen(true)
                  //   setSelectedId(item?._id)
                  // }}
                  onClick={() => {
                    setOpen(true);
                    setSelectedId(item?._id);
                  }}
                  >
                    <DeleteIcon />
                  </IconButton>
                    ):null}
                  {/* <IconButton
                    onClick={() => navigate("/manage-advertisements/edit")}
                  >
                    <ModeEditIcon />
                  </IconButton> */}
                     {/* {hidePermission?.isDelete ||
                            userData?.role === "ADMIN" ? (
                  <IconButton 
                  onClick={()=>{
                    setOpen(true)
                    setSelectedId(item?._id)
                  }}
                  // onClick={() => {
                  //   setOpen(true);
                  //   setSelectedId(item?._id);
                  // }}
                  >
                    <DeleteIcon />
                  </IconButton>
                            ):null} */}
                            </Box>
                          </TableCell>
                      </TableRow>
                    </TableBody>
                  ))
                ) : (
                  <TableBody>
                    <TableCell
                      align="center"
                      colSpan={10}
                      sx={{ color: "#051140" }}
                    >
                    {translations.Globals.no_reward||""}
                    </TableCell>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Box>
        </Card>
      </div>
      <Pagination
      module={rewards}
      onPageChange={onPageChange}
      page={page}
      totalPages={totalPages}
      
      />
      <WarnModal
        setOpen={setOpen}
        open={open}
        name= {translations.Globals.reward||""}
              handleDelete={()=>{
                handleDelete(deleteById,selectId,fetchRewards)
              }}
        />
      
    </MainContainer>
  );
};

export default ManageRewards;
